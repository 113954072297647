import React, { useState } from "react";
import { ArrowForward, PermContactCalendar, Wc } from "@mui/icons-material";
import * as S from "./styled";
import { useAtom } from "jotai";
import {
  chattingIntonationAtom,
  chattingValueAtom,
  toneListAtom,
  typeAvailableStateAtom,
} from "../../../../Atoms/ChatTestAtom";
import {
  handleInputChange,
  handleKeyDown,
  handleSendMessage,
} from "../../../../ViewModels/ChatTestViewModel";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export function ChatInput() {
  const [inputValue] = useAtom(chattingValueAtom);
  const [, onInputChange] = useAtom(handleInputChange);
  const [, onSendMessage] = useAtom(handleSendMessage);
  const [, onKeyDown] = useAtom(handleKeyDown);
  const [typeAvailableState] = useAtom(typeAvailableStateAtom);

  const [chattingIntonaion, setChattingIntonation] = useAtom(
    chattingIntonationAtom
  );
  const [toneList] = useAtom(toneListAtom);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl); // Popover를 열지 여부

  const { t } = useTranslation();

  return (
    <S.InputContainer>
      {/* 말투 선택 버튼 */}
      <Button
        variant="contained"
        sx={{
          background: process.env.REACT_APP_MAIN_COLOR,
          borderRadius: "30px",
          alignSelf: "end",
          height: "40px",
          "&:hover": {
            background: process.env.REACT_APP_MAIN_COLOR,
          },
        }}
        onClick={handleMenuOpen}
      >
        {chattingIntonaion === null
          ? t("Components.ChatTest.ChatInput.intonation")
          : chattingIntonaion.name}
      </Button>

      {/* Popover */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              border: "1px solid #8f8f8f",
              borderRadius: "8px",
              backgroundColor: "#fff",
              width: "30vw",
              maxHeight: "32vh",
              overflow: "auto",
              mt: -2,
              p: 2,
            },
          },
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2} alignItems="stretch">
            {toneList.map((tone, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
                key={tone.id ?? index}
                sx={{ position: "relative" }}
              >
                <Card
                  onClick={() => {
                    setChattingIntonation((current) => {
                      if (current && current.id === tone.id) return null;
                      else return tone;
                    });
                    handleMenuClose();
                  }}
                  sx={{
                    height: "calc(100% - 20px)",
                    padding: "10px",
                    borderRadius: "15px",
                    border: "1px solid rgb(206, 206, 206)",
                    textAlign: "center",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "start",
                    "&:hover": {
                      background: process.env.REACT_APP_MAIN_COLOR,
                      ".have-tobe-changed-when-hover-background": {
                        background: "white",
                        color: process.env.REACT_APP_MAIN_COLOR,
                      },
                      ".have-tobe-changed-when-hover-color": {
                        color: "white",
                      },
                    },
                  }}
                >
                  {/* 말투 상태 */}
                  {chattingIntonaion?.id === tone.id && (
                    <Chip
                      label={t(
                        "Components.ChatTest.BasicCenter.ChatInput.using"
                      )}
                      className="have-tobe-changed-when-hover-background"
                      sx={{
                        position: "absolute",
                        top: 5,
                        left: 21,
                        fontSize: "10px",
                        background: process.env.REACT_APP_MAIN_COLOR,
                        border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
                        color: "white",
                      }}
                    />
                  )}
                  {/* 아바타 이미지 */}
                  <Avatar
                    src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${tone?.image_url}`}
                    sx={{
                      width: 60,
                      height: 60,
                      border: "2px solid #fff",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                  {/* 이름 */}
                  <Typography
                    className="have-tobe-changed-when-hover-color"
                    fontSize="14px"
                    fontWeight="bold"
                    sx={{ color: "#333" }}
                  >
                    {tone.name ?? "-"}
                  </Typography>
                  {/* 나이 & 성별을 하나의 Stack으로 묶어 같은 선상에 표시 */}
                  <Box
                    sx={{
                      display: "grid", // CSS Grid 레이아웃 사용
                      gridTemplateColumns: "auto 1fr", // 첫 번째 열은 아이콘, 두 번째 열은 텍스트
                      rowGap: "8px", // 행 간 간격
                      columnGap: "8px", // 열 간 간격 (아이콘과 텍스트 사이)
                      alignItems: "center", // 아이콘과 텍스트 세로 중앙 정렬
                      marginTop: "8px",
                    }}
                  >
                    {/* 나이 (첫 번째 행) */}
                    <PermContactCalendar
                      className="have-tobe-changed-when-hover-color"
                      sx={{
                        fontSize: "20px",
                        color: "#8f8f8f",
                        alignSelf: "start",
                      }}
                    />
                    <Typography
                      className="have-tobe-changed-when-hover-color"
                      fontSize="12px"
                      sx={{
                        color: "#666",
                        textAlign: "start",
                        whiteSpace: "normal",
                        wordBreak: "break-all",
                        overflowWrap: "break-word",
                      }}
                    >
                      {t("Components.ChatTest.BasicCenter.ChatInput.age", {
                        age: tone.age ?? "-",
                      })}
                    </Typography>

                    {/* 성별 (두 번째 행) */}
                    <Wc
                      className="have-tobe-changed-when-hover-color"
                      sx={{
                        fontSize: "20px",
                        color: "#8f8f8f",
                        alignSelf: "start",
                      }}
                    />
                    <Typography
                      className="have-tobe-changed-when-hover-color"
                      fontSize="12px"
                      sx={{
                        color: "#666",
                        textAlign: "start",
                        whiteSpace: "normal",
                        wordBreak: "break-all",
                        overflowWrap: "break-word",
                      }}
                    >
                      {tone.gender ?? "-"}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>

      {/* 채팅 입력창 */}
      <S.CustomInputBase
        placeholder={
          typeAvailableState
            ? t("Components.ChatTest.ChatInput.ask")
            : t("Components.ChatTest.ChatInput.answer")
        }
        multiline
        maxRows={10}
        value={inputValue}
        onChange={onInputChange}
        disabled={!typeAvailableState}
        onKeyDown={
          typeAvailableState ? (event) => onKeyDown(event, t) : () => null
        }
      />

      {/* 전송버튼 or 로딩 */}
      {typeAvailableState ? (
        <S.CustomIconButton onClick={() => onSendMessage(t)}>
          <ArrowForward />
        </S.CustomIconButton>
      ) : (
        <CircularProgress
          sx={{ alignSelf: "end", color: process.env.REACT_APP_MAIN_COLOR }}
        />
      )}
    </S.InputContainer>
  );
}
