// BarChart.tsx
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";
import useResizeObserver from "../useResizeObserver"; // 방금 만든 훅을 임포트

interface DataPoint {
  x: string; // x 값이 문자열
  y: number; // y 값이 숫자
}

interface BarChartProps {
  chartTitle: string;
  xLabel: string;
  yLabel: string;
  data: DataPoint[];
}

const BarChart: React.FC<BarChartProps> = ({
  chartTitle,
  xLabel,
  yLabel,
  data,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const { ref, dimensions } = useResizeObserver();

  useEffect(() => {
    if (!dimensions || data.length === 0) return;

    const { width, height } = dimensions;

    // Chart dimensions
    const margin = { top: 10, right: 10, bottom: 60, left: 60 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Prevent negative dimensions
    if (innerWidth <= 0 || innerHeight <= 0) return;

    // Clear previous chart
    d3.select(svgRef.current).selectAll("*").remove();

    // Create SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`) // 반응형을 위한 viewBox 설정
      .attr("preserveAspectRatio", "xMidYMid meet"); // 비율 유지

    // Create chart group
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create scales
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.x)) // x값 리스트
      .range([0, innerWidth])
      .padding(0.2);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.y)!]) // y값 범위 설정
      .nice() // 축 눈금 자연스럽게
      .range([innerHeight, 0]);

    // Add X-axis
    chart
      .append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .attr("font-size", "10px") // 폰트 크기 조정
      .attr("text-anchor", "end") // 텍스트 앵커를 끝으로 설정
      .attr("transform", "rotate(-45)") // 45도 회전
      .attr("dx", "-0.5em") // X 위치 조정
      .attr("dy", "0.5em"); // Y 위치 조정

    // Add X-axis label
    chart
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", innerHeight + margin.bottom - 15)
      .attr("text-anchor", "middle")
      .style("font-size", "12px") // 폰트 크기 조정
      .style("font-weight", "bold")
      .text(xLabel);

    // Add Y-axis
    chart
      .append("g")
      .call(d3.axisLeft(yScale).ticks(5)) // y축 눈금 조정
      .selectAll("text")
      .attr("font-size", "10px"); // 폰트 크기 조정

    // Add Y-axis label
    chart
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -innerHeight / 2)
      .attr("y", -margin.left + 20)
      .attr("text-anchor", "middle")
      .style("font-size", "12px") // 폰트 크기 조정
      .style("font-weight", "bold")
      .text(yLabel);

    // Add chart title
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", margin.top / 2 + 10) // 제목 위치 조정
      .attr("text-anchor", "middle")
      .style("font-size", "14px") // 폰트 크기 조정
      .style("font-weight", "bold")
      .text(chartTitle);

    // Add bars with animation
    chart
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.x)!)
      .attr("y", innerHeight)
      .attr("width", xScale.bandwidth())
      .attr("height", 0)
      .style("fill", process.env.REACT_APP_MAIN_COLOR) // 막대 색상 변경 가능 (예: 스틸 블루)
      .transition()
      .duration(800)
      .attr("y", (d) => yScale(d.y))
      .attr("height", (d) => innerHeight - yScale(d.y))
      .delay((d, i) => i * 100);

    // Optional: Tooltip 추가
    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("background", "rgba(0, 0, 0, 0.6)")
      .style("color", "#fff")
      .style("padding", "5px 10px")
      .style("border-radius", "4px")
      .style("font-size", "12px");

    // 막대에 마우스 이벤트 추가
    chart
      .selectAll(".bar")
      .on("mouseover", (event, d: any) => {
        tooltip
          .style("visibility", "visible")
          .html(`X: ${d.x}<br>Y: ${d.y}`)
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        tooltip.style("visibility", "hidden");
      });

    // 컴포넌트 언마운트 시 툴팁 제거
    return () => {
      tooltip.remove();
    };
  }, [chartTitle, xLabel, yLabel, data, dimensions]);

  return (
    <Box
      ref={ref}
      sx={{
        width: "100%",
        height: "100%",
        padding: "15px",
        borderRadius: "5px",
        background: "white",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <svg
        ref={svgRef}
        style={{ flexGrow: 1 }}
        viewBox={`0 0 ${dimensions?.width || 600} ${dimensions?.height || 400}`}
        preserveAspectRatio="xMidYMid meet"
      ></svg>
    </Box>
  );
};

export default BarChart;
