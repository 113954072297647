import React, { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";

import {
  CardActions,
  CardContent,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Grid,
  Card,
  Box,
} from "@mui/material";
import {
  ArrowBackIosNew,
  ArrowForward,
  ArrowForwardIos,
  Close,
  QuestionMark,
} from "@mui/icons-material";
import * as S from "./styled";
import {
  frontElementListAtom,
  isOpenRecommendQuestionAtom,
  leftSideOpenAtom,
  recommendCurrentPageAtom,
} from "../../../../Atoms/ChatTestAtom";
import { hanldeRecommendQuestionAtom } from "../../../../ViewModels/ChatTestViewModel";
import { useTranslation } from "react-i18next";

export function InteractionRecommendQuestion() {
  const [isOpenRecommendQuestion, setIsOpenRecommendQuestion] = useAtom(
    isOpenRecommendQuestionAtom
  );

  const [recommendQuestionList] = useAtom(frontElementListAtom);
  const [, hanldeRecommendQuestion] = useAtom(hanldeRecommendQuestionAtom);

  const [leftSideOpen, setLeftSideOpen] = useAtom(leftSideOpenAtom);

  // 페이지 관련 상태
  const [page, setPage] = useAtom(recommendCurrentPageAtom);

  // 테마와 화면 크기 감지
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // 1024px 이하
  // Use media queries to detect screen sizes
  const isXs = useMediaQuery(theme.breakpoints.down("sm")); // xs and below
  const isSm = useMediaQuery(theme.breakpoints.only("sm")); // sm
  const isMd = useMediaQuery(theme.breakpoints.only("md")); // md
  const isLg = useMediaQuery(theme.breakpoints.only("lg")); // lg
  const isXl = useMediaQuery(theme.breakpoints.up("xl")); // xl and above

  const boxRef = useRef<HTMLDivElement | null>(null);

  // Determine itemsPerPage dynamically
  const itemsPerPage = leftSideOpen
    ? 2
    : isXl
    ? 4
    : isLg || isMd
    ? 3
    : isSm || isXs
    ? 2
    : 1; // Default fallback

  // 전체 페이지 수 계산
  const totalPages = Math.ceil(
    ((recommendQuestionList.filter((value, index) => value.key === "추천질문")
      ?.length ?? 0) +
      1) /
      itemsPerPage
  );

  const { t } = useTranslation();

  // 페이지 전환 핸들러
  const handleNextPage = () => {
    if (page < totalPages - 1 && boxRef.current) {
      setPage(page + 1);
      boxRef.current.scrollLeft += boxRef.current.clientWidth;
    }
  };

  const handlePrevPage = () => {
    if (page > 0 && boxRef.current) {
      setPage(page - 1);
      boxRef.current.scrollLeft -= boxRef.current.clientWidth;
    }
  };

  useEffect(() => {
    if (page > totalPages - 1 && boxRef.current) {
      setPage(totalPages - 1);
      boxRef.current.scrollLeft = boxRef.current.clientWidth * (totalPages - 1);
    }
  }, [itemsPerPage]);

  useEffect(() => {
    const handleResize = () => {
      if (boxRef.current)
        boxRef.current.scrollLeft = boxRef.current.clientWidth * page;
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [page]);

  const handleCloseModal = () => {
    // 필요한 경우 모달을 닫는 로직을 추가
    setIsOpenRecommendQuestion(false);
  };

  if (isSmallScreen && isOpenRecommendQuestion) {
    return (
      <Dialog
        open={isOpenRecommendQuestion}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>
            {t("Components.ChatTest.InteractionCenter.RecommendQuestion.title")}
          </Typography>
          <IconButton onClick={() => setIsOpenRecommendQuestion(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <Grid
          container
          sx={{ maxHeight: "100%", overflowY: "auto", padding: "12px 24px" }}
        >
          {recommendQuestionList
            .filter((value, index) => value.key === "추천질문")
            ?.map((ques, index) => (
              <Grid xs={12} sm={6}>
                <S.CustomMobileCard
                  key={`recommend-question-mobile-${index}-interaction`}
                  onClick={() => hanldeRecommendQuestion(ques.value)}
                >
                  <CardContent>{ques.value}</CardContent>
                  <CardActions sx={{ justifyContent: "end" }}>
                    <IconButton aria-label="add to favorites">
                      <ArrowForward />
                    </IconButton>
                  </CardActions>
                </S.CustomMobileCard>
              </Grid>
            ))}
        </Grid>
      </Dialog>
    );
  }

  if (isOpenRecommendQuestion) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        {/* Left navigation button */}
        <IconButton
          aria-label="back"
          style={{
            borderRadius: "20px",
            color: page === 0 ? "#8f8f8f" : process.env.REACT_APP_MAIN_COLOR,
          }}
          onClick={handlePrevPage}
          disabled={page === 0}
        >
          <ArrowBackIosNew style={{ fontSize: `30px` }} />
        </IconButton>

        {/* Main Grid content */}
        <Box
          ref={boxRef}
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            flexWrap: "nowrap",
            overflowX: "hidden",
            height: "100%",
          }}
        >
          <Card
            sx={{
              minWidth: `calc((100% - ${
                itemsPerPage * 5 - 1
              }px) / ${itemsPerPage})`,
              maxWidth: `calc((100% - ${
                itemsPerPage * 5 - 1
              }px) / ${itemsPerPage})`,
              background: process.env.REACT_APP_MAIN_COLOR,
              border: "1px solid #afafaf",
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              boxSizing: "border-box",
            }}
          >
            <CardContent>
              <Typography>
                {t(
                  "Components.ChatTest.InteractionCenter.RecommendQuestion.whatAboutthis"
                )}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "end" }}>
              <IconButton aria-label="add to favorites" sx={{ color: "white" }}>
                <QuestionMark />
              </IconButton>
            </CardActions>
          </Card>
          {/* Recommendation Cards */}
          {recommendQuestionList
            .filter((value, index) => value.key === "추천질문")
            .map((value, index) => (
              <Card
                key={`${index}-interaction-recommend-question`}
                sx={{
                  minWidth: `calc((100% - ${
                    itemsPerPage * 5 - 1
                  }px) / ${itemsPerPage})`,
                  maxWidth: `calc((100% - ${
                    itemsPerPage * 5 - 1
                  }px) / ${itemsPerPage})`,
                  background: "#efefef",
                  border: "1px solid #afafaf",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                onClick={() => hanldeRecommendQuestion(value.value)}
              >
                <CardContent>
                  <Typography
                    sx={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {value.value}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "end" }}>
                  <IconButton aria-label="add to favorites">
                    <ArrowForward />
                  </IconButton>
                </CardActions>
              </Card>
            ))}
        </Box>

        {/* Right navigation button */}
        <IconButton
          aria-label="back"
          style={{
            borderRadius: "20px",
            color:
              page === totalPages - 1
                ? "#8f8f8f"
                : process.env.REACT_APP_MAIN_COLOR,
          }}
          onClick={handleNextPage}
          disabled={page === totalPages - 1}
        >
          <ArrowForwardIos style={{ fontSize: `30px` }} />
        </IconButton>
      </Box>
    );
  }

  return null;
}
