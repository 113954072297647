import { useAtom } from "jotai";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import { isOpenRecommendQuestionAtom } from "../../../../Atoms/ChatTestAtom";
import { useTranslation } from "react-i18next";

export function InteractionRecommendQuestionButton() {
  const [isOpenRecommendQuestion, setIsOpenRecommendQuestion] = useAtom(
    isOpenRecommendQuestionAtom
  );

  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      sx={{
        borderRadius: "20px",
        background: process.env.REACT_APP_MAIN_COLOR,
        "&:hover": {
          background: "rgb(38, 23, 167)",
        },
      }}
      onClick={() => setIsOpenRecommendQuestion((current) => !current)}
    >
      {isOpenRecommendQuestion
        ? t(
            "Components.ChatTest.InteractionCenter.RecommendQuestionButton.open"
          )
        : t(
            "Components.ChatTest.InteractionCenter.RecommendQuestionButton.close"
          )}
      {isOpenRecommendQuestion ? (
        <KeyboardArrowUpIcon />
      ) : (
        <KeyboardArrowDownIcon />
      )}
    </Button>
  );
}
