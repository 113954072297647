import { atom } from "jotai";
import {
  AllFlowProps,
  chattingIntonationAtom,
  chatListAtom,
  chatTestFlowAtom,
  chattingAccessTokenAtom,
  chattingValueAtom,
  frontElementListAtom,
  isInitializeFinishAtom,
  toneListAtom,
  typeAvailableStateAtom,
  userIdAtom,
  userLanguageAtom,
  scrollStateAtom,
  isChattingAskAtom,
  chattingTypeAtom,
  ChatType,
  currentSearchIndexAtom,
  searchValueAtom,
  isOpenRecommendQuestionAtom,
  dataListAtom,
  mapDataAtom,
  leftSideOpenAtom,
  dataWaitingListAtom,
  errorAtom,
} from "../Atoms/ChatTestAtom";
import dayjs from "dayjs";
import {
  getAllaboutFlow,
  requestChatbot,
  virtualTryOn,
} from "../Queries/DokgabiChatbotQueries";
import { ChattingEnum } from "../Enum/ChattingEnum";
import { ResponseStatus } from "../Enum/NetworkEnum";

export const handleImageClickAtom = atom(
  null,
  async (get, set, data: any, t: any) => {
    const flow = get(chatTestFlowAtom);
    const accessToken = get(chattingAccessTokenAtom);

    const newMessage = {
      name: flow!.name,
      image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
        flow!.character_image!.file_path
      }`,
      text: t("ViewModels.ChatTestViewModel.wearCloth", {
        wearCloth: data.name,
      }),
      date: dayjs().format("MM.DD HH:mm"),
      chattingUser: "user",
      data: null,
    };

    const chatbotNewMessage = {
      name: flow!.name,
      image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
        flow!.character_image!.file_path
      }`,
      text: t("ViewModels.ChatTestViewModel.noticeWear"),
      date: dayjs().format("MM.DD HH:mm"),
      chattingUser: "chatbot",
      data: null,
    };

    set(chatListAtom, (prevChatting) => [
      ...prevChatting,
      newMessage,
      chatbotNewMessage,
    ]);

    set(dataWaitingListAtom, (current) =>
      current.length === 0 ? [1] : [...current, current.length]
    );

    set(mapDataAtom, null);

    const model_image =
      "https://dashboard.dokgabi.ai/api/home/dokgabi/media/model1.webp";
    const category = "upper_body";
    const cloth_url = data.imgUrl;
    const cloth_name = data.name;

    const result = await virtualTryOn(
      accessToken ?? "",
      category,
      cloth_url,
      model_image ?? "",
      cloth_name
    );

    if (
      result === ResponseStatus.FORBIDDEN ||
      result === ResponseStatus.UNAUTHORIZED
    ) {
      const chatbotNewMessage2 = {
        name: flow!.name,
        image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
          flow!.character_image!.file_path
        }`,
        text: t("ViewModels.ChatTestViewModel.noChatbot"),
        date: dayjs().format("MM.DD HH:mm"),
        chattingUser: "chatbot",
        data: null,
      };

      set(dataWaitingListAtom, (current) =>
        current.length > 0 ? current.slice(1) : current
      );

      set(chatListAtom, (prevChatting) => [
        ...prevChatting,
        chatbotNewMessage2,
      ]);
    } else if (result === ResponseStatus.ERROR) {
      const chatbotNewMessage2 = {
        name: flow!.name,
        image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
          flow!.character_image!.file_path
        }`,
        text: t("ViewModels.ChatTestViewModel.fail"),
        date: dayjs().format("MM.DD HH:mm"),
        chattingUser: "chatbot",
        data: null,
      };

      set(dataWaitingListAtom, (current) =>
        current.length > 0 ? current.slice(1) : current
      );

      set(chatListAtom, (prevChatting) => [
        ...prevChatting,
        chatbotNewMessage2,
      ]);
    } else {
      const chatbotNewMessage2 = {
        name: flow!.name,
        image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
          flow!.character_image!.file_path
        }`,
        text: t("ViewModels.ChatTestViewModel.successWear"),
        date: dayjs().format("MM.DD HH:mm"),
        chattingUser: "chatbot",
        data: null,
      };

      set(chatListAtom, (prevChatting) => [
        ...prevChatting,
        chatbotNewMessage2,
      ]);

      set(leftSideOpenAtom, true);

      set(dataWaitingListAtom, (current) =>
        current.length > 0 ? current.slice(1) : current
      );

      set(dataListAtom, (current) => [...current, result as any]);
    }
  }
);

export const hanldeRecommendQuestionAtom = atom(
  null,
  (get, set, value: string) => {
    set(chattingTypeAtom, ChatType.ASK);
    set(chattingValueAtom, value);
    set(isOpenRecommendQuestionAtom, false);
  }
);

export const whenDownButtonClickAtom = atom(null, (get, set) => {
  const currentIdx = get(currentSearchIndexAtom);
  const highlightsLength = document.getElementsByClassName(
    "highlight-search-field"
  ).length;

  setTimeout(() => {
    if (currentIdx + 1 < highlightsLength) {
      // currentIdx가 highlight 길이를 넘지 않도록
      const firstHighlight = document.getElementsByClassName(
        "highlight-search-field"
      )[currentIdx + 1];
      if (firstHighlight) {
        firstHighlight.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      set(currentSearchIndexAtom, (current) => current + 1);
    }
  }, 100);
});

export const whenUpButtonClickAtom = atom(null, (get, set) => {
  const currentIdx = get(currentSearchIndexAtom);

  setTimeout(() => {
    const firstHighlight = document.getElementsByClassName(
      "highlight-search-field"
    )[currentIdx - 1];
    if (firstHighlight) {
      firstHighlight.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    set(currentSearchIndexAtom, (current) =>
      current != 0 ? current - 1 : current
    );
  }, 100);
});

export const handleClickAtom = atom(
  null,
  (_, set, event: React.MouseEvent<HTMLDivElement>) => {
    set(isChattingAskAtom, event.currentTarget);
  }
);

export const handleCloseAtom = atom(null, (_, set) => {
  set(isChattingAskAtom, null);
});

export const handleCloseAndChangeTypeAtom = atom(
  null,
  (get, set, type: ChatType) => {
    const chatType = get(chattingTypeAtom);
    if (chatType !== type) {
      set(isChattingAskAtom, null);
      set(chattingTypeAtom, type);
      set(currentSearchIndexAtom, 0);
    }
  }
);

export const handleInitializeAtom = atom(
  null,
  async (
    get,
    set,
    socketParameter: string,
    userId: string,
    language: string,
    t: any
  ) => {
    set(userIdAtom, userId);
    set(userLanguageAtom, language);
    set(isInitializeFinishAtom, false);

    const result = await getAllaboutFlow(socketParameter, userId, language);

    if (
      result === ChattingEnum.NO_PARAMETER ||
      result === ChattingEnum.NETWORK_ERROR ||
      result === ChattingEnum.SOMETHING_WRONG
    ) {
      set(errorAtom, t("ViewModels.ChatTestViewModel.refresh"));
    } else if (result === ChattingEnum.NO_FLOW) {
      set(errorAtom, t("ViewModels.ChatTestViewModel.noChatbot2"));
    } else if (result === ChattingEnum.ACCESS_TOKEN_ERROR) {
      set(errorAtom, t("ViewModels.ChatTestViewModel.wrongDirection"));
    } else if (result === ChattingEnum.EXPIRE_DATE_ERROR) {
      set(errorAtom, t("ViewModels.ChatTestViewModel.expireChatbot"));
    } else {
      set(chattingAccessTokenAtom, (result as AllFlowProps).access_token);
      set(chatTestFlowAtom, (result as AllFlowProps).flow);
      set(frontElementListAtom, (result as AllFlowProps).front_element);
      set(toneListAtom, (result as AllFlowProps).tone);
      set(chatListAtom, (result as AllFlowProps).chat);
      set(
        chattingIntonationAtom,
        (result as AllFlowProps).flow.flow_used_intonation &&
          (result as AllFlowProps).flow.flow_used_intonation?.language ===
            language
          ? (result as AllFlowProps).flow.flow_used_intonation
          : null
      );

      const flow = (result as AllFlowProps).flow;
      set(errorAtom, null);
      if ((result as AllFlowProps).chat.length === 0) {
        const character = flow.flow_used_intonation;
        set(chatListAtom, (current) => [
          ...current,
          {
            name: flow.name,
            image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
              flow!.character_image!.file_path
            }`,
            text: "",
            date: dayjs().format("MM.DD HH:mm"),
            chattingUser: "chatbot",
            data: null,
          },
        ]);

        // 여기에서 backend랑 통신을 하도록 하자.
        const handleStreamMessage = (
          message: string | null,
          isFirst: boolean
        ) => {
          if (isFirst) set(scrollStateAtom, true);

          if (isFirst && message) {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: message,
                  },
                ];
              } else if (
                lastItem.chattingUser === "chatbot" &&
                lastItem.text !== ""
              ) {
                return [
                  ...current,
                  {
                    name: flow.name,
                    image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                      flow!.character_image!.file_path
                    }`,
                    text: message,
                    date: dayjs().format("MM.DD HH:mm"),
                    data: null,
                    chattingUser: "chatbot",
                  },
                ];
              }

              return current;
            });
          }

          if (!isFirst && message) {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem) {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: message,
                  },
                ];
              }

              return current;
            });
          }
        };

        const handleJson = (data: any) => {
          if (Array.isArray(data)) {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: t("ViewModels.ChatTestViewModel.responseError"),
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text: t("ViewModels.ChatTestViewModel.responseError"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data: null,
                  chattingUser: "chatbot",
                },
              ];
            });
          } else if (data.type === "map") {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? t("ViewModels.ChatTestViewModel.noMap")
                        : t("ViewModels.ChatTestViewModel.showMap"),
                    data: null,
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? t("ViewModels.ChatTestViewModel.noMap")
                      : t("ViewModels.ChatTestViewModel.showMap"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data: null,
                  chattingUser: "chatbot",
                },
              ];
            });

            set(leftSideOpenAtom, true);
            set(mapDataAtom, data);
            // set(dataListAtom, (current) => [...current, data]);
          } else if (
            data.type === "bar" ||
            data.type === "line" ||
            data.type === "scatter"
          ) {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? t("ViewModels.ChatTestViewModel.noChart")
                        : t("ViewModels.ChatTestViewModel.showChart"),
                    data: null,
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? t("ViewModels.ChatTestViewModel.noChart")
                      : t("ViewModels.ChatTestViewModel.showChart"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data: null,
                  chattingUser: "chatbot",
                },
              ];
            });

            if (!Array.isArray(data.data) || data.data.length === 0) {
              set(leftSideOpenAtom, false);
            } else {
              set(mapDataAtom, null);
              set(leftSideOpenAtom, true);
              set(dataListAtom, (current) => [...current, data]);
            }
          } else if (data.type === "image") {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? t("ViewModels.ChatTestViewModel.noImage")
                        : t("ViewModels.ChatTestViewModel.showImage"),
                    data:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? {
                            type: "image",
                            data: [
                              {
                                imgUrl:
                                  "https://dashboard.dokgabi.ai/ieum_logo.png",
                                name: t(
                                  "ViewModels.ChatTestViewModel.noImageName"
                                ),
                                desc: t(
                                  "ViewModels.ChatTestViewModel.noImageDesc"
                                ),
                              },
                            ],
                          }
                        : data,
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? t("ViewModels.ChatTestViewModel.noImage")
                      : t("ViewModels.ChatTestViewModel.showImage"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? {
                          type: "image",
                          data: [
                            {
                              imgUrl:
                                "https://dashboard.dokgabi.ai/ieum_logo.png",
                              name: t(
                                "ViewModels.ChatTestViewModel.noImageName"
                              ),
                              desc: t(
                                "ViewModels.ChatTestViewModel.noImageDesc"
                              ),
                            },
                          ],
                        }
                      : data,
                  chattingUser: "chatbot",
                },
              ];
            });
          } else if (data.type === "etc") {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? t("ViewModels.ChatTestViewModel.noEtc")
                        : t("ViewModels.ChatTestViewModel.showEtc"),
                    data:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? {
                            type: "image",
                            data: [
                              {
                                imgUrl:
                                  "https://dashboard.dokgabi.ai/ieum_logo.png",
                                name: t(
                                  "ViewModels.ChatTestViewModel.noEtcName"
                                ),
                                desc: t(
                                  "ViewModels.ChatTestViewModel.noEtcDesc"
                                ),
                              },
                            ],
                          }
                        : data,
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? t("ViewModels.ChatTestViewModel.noEtc")
                      : t("ViewModels.ChatTestViewModel.showEtc"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? {
                          type: "image",
                          data: [
                            {
                              imgUrl:
                                "https://dashboard.dokgabi.ai/ieum_logo.png",
                              name: t("ViewModels.ChatTestViewModel.noEtcName"),
                              desc: t("ViewModels.ChatTestViewModel.noEtcDesc"),
                            },
                          ],
                        }
                      : data,
                  chattingUser: "chatbot",
                },
              ];
            });
          } else {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: t("ViewModels.ChatTestViewModel.responseError"),
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text: t("ViewModels.ChatTestViewModel.responseError"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data: null,
                  chattingUser: "chatbot",
                },
              ];
            });
          }
        };

        const accessToken = get(chattingAccessTokenAtom);

        const result = await requestChatbot(
          accessToken ?? "",
          "",
          get(chatTestFlowAtom)?.chatbot_socket_parameter ?? "",
          character?.id ?? -1,
          userId,
          handleStreamMessage,
          handleJson
        );

        if (
          result === ResponseStatus.FORBIDDEN ||
          result === ResponseStatus.UNAUTHORIZED
        ) {
          set(chatListAtom, (current) => {
            const lastItem = current[current.length - 1];

            if (lastItem.text === "") {
              return [
                ...current.slice(0, -1),
                {
                  ...lastItem,
                  text: t("ViewModels.ChatTestViewModel.serviceEnd"),
                },
              ];
            } else {
              return [
                ...current,
                {
                  name: flow.name,
                  image: flow.character_image
                    ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
                    : "",
                  text: t("ViewModels.ChatTestViewModel.serviceEnd"),
                  data: null,
                  date: dayjs().format("MM.DD HH:mm"),
                  chattingUser: "chatbot",
                },
              ];
            }
          });
        } else if (result === ResponseStatus.ERROR) {
          set(chatListAtom, (current) => {
            const lastItem = current[current.length - 1];

            if (lastItem.text === "") {
              return [
                ...current.slice(0, -1),
                {
                  ...lastItem,
                  text: t("ViewModels.ChatTestViewModel.responseError"),
                },
              ];
            } else {
              return [
                ...current,
                {
                  name: flow.name,
                  image: flow.character_image
                    ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
                    : "",
                  text: t("ViewModels.ChatTestViewModel.responseError"),
                  data: null,
                  date: dayjs().format("MM.DD HH:mm"),
                  chattingUser: "chatbot",
                },
              ];
            }
          });
        }
      }
    }

    set(isInitializeFinishAtom, true);
  }
);

export const handleInputChange = atom(
  null,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(chattingValueAtom, event.target.value);
  }
);

export const handleInputValueAtom = atom(null, (get, set, value: string) => {
  const chatType = get(chattingTypeAtom);

  if (chatType === ChatType.ASK) set(chattingValueAtom, value);
  else {
    set(currentSearchIndexAtom, 0);
    set(searchValueAtom, value);
  }
});

// 건드리지도 말것 --------------------------------------------------------------------
export const handleSendMessage = atom(null, async (get, set, t: any) => {
  const inputValue = get(chattingValueAtom).trim();
  const userId = get(userIdAtom);

  if (inputValue === "" || !userId) return;

  set(typeAvailableStateAtom, false);
  const flow = get(chatTestFlowAtom);
  const character = get(chattingIntonationAtom);

  if (flow) {
    const newMessage = {
      name: flow.name,
      image: flow.character_image
        ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
        : "",
      text: inputValue,
      data: null,
      date: dayjs().format("MM.DD HH:mm"),
      chattingUser: "user",
      type: "text",
    };

    set(chatListAtom, (prevChatting) => [...prevChatting, newMessage]);
    set(chattingValueAtom, "");

    set(chatListAtom, (current) => [
      ...current,
      {
        name: flow.name,
        image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
          flow!.character_image!.file_path
        }`,
        text: "",
        date: dayjs().format("MM.DD HH:mm"),
        chattingUser: "chatbot",
        data: null,
      },
    ]);

    // 여기에서 backend랑 통신을 하도록 하자.
    const handleStreamMessage = (message: string | null, isFirst: boolean) => {
      if (isFirst) set(scrollStateAtom, true);

      if (isFirst && message) {
        set(chatListAtom, (current) => {
          const lastItem = current[current.length - 1];

          if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text: message,
              },
            ];
          } else if (
            lastItem.chattingUser === "chatbot" &&
            lastItem.text !== ""
          ) {
            return [
              ...current,
              {
                name: flow.name,
                image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                  flow!.character_image!.file_path
                }`,
                text: message,
                date: dayjs().format("MM.DD HH:mm"),
                data: null,
                chattingUser: "chatbot",
              },
            ];
          }

          return current;
        });
      }

      if (!isFirst && message) {
        set(chatListAtom, (current) => {
          const lastItem = current[current.length - 1];

          if (lastItem) {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text: message,
              },
            ];
          }

          return current;
        });
      }
    };

    const handleJson = (data: any) => {
      if (Array.isArray(data)) {
        set(chatListAtom, (current) => {
          const lastItem = current[current.length - 1];

          if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text: t("ViewModels.ChatTestViewModel.responseError"),
              },
            ];
          }
          return [
            ...current,
            {
              name: flow.name,
              image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                flow!.character_image!.file_path
              }`,
              text: t("ViewModels.ChatTestViewModel.responseError"),
              date: dayjs().format("MM.DD HH:mm"),
              data: null,
              chattingUser: "chatbot",
            },
          ];
        });
      } else if (data.type === "map") {
        set(chatListAtom, (current) => {
          const lastItem = current[current.length - 1];

          if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text:
                  !Array.isArray(data.data) || data.data.length === 0
                    ? t("ViewModels.ChatTestViewModel.noMap")
                    : t("ViewModels.ChatTestViewModel.showMap"),
                data: null,
              },
            ];
          }
          return [
            ...current,
            {
              name: flow.name,
              image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                flow!.character_image!.file_path
              }`,
              text:
                !Array.isArray(data.data) || data.data.length === 0
                  ? t("ViewModels.ChatTestViewModel.noMap")
                  : t("ViewModels.ChatTestViewModel.showMap"),
              date: dayjs().format("MM.DD HH:mm"),
              data: null,
              chattingUser: "chatbot",
            },
          ];
        });

        set(leftSideOpenAtom, true);
        set(mapDataAtom, data);
        // set(dataListAtom, (current) => [...current, data]);
      } else if (
        data.type === "bar" ||
        data.type === "line" ||
        data.type === "scatter"
      ) {
        set(chatListAtom, (current) => {
          const lastItem = current[current.length - 1];

          if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text:
                  !Array.isArray(data.data) || data.data.length === 0
                    ? t("ViewModels.ChatTestViewModel.noChart")
                    : t("ViewModels.ChatTestViewModel.showChart"),
                data: null,
              },
            ];
          }
          return [
            ...current,
            {
              name: flow.name,
              image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                flow!.character_image!.file_path
              }`,
              text:
                !Array.isArray(data.data) || data.data.length === 0
                  ? t("ViewModels.ChatTestViewModel.noChart")
                  : t("ViewModels.ChatTestViewModel.showChart"),
              date: dayjs().format("MM.DD HH:mm"),
              data: null,
              chattingUser: "chatbot",
            },
          ];
        });

        if (!Array.isArray(data.data) || data.data.length === 0) {
          set(leftSideOpenAtom, false);
        } else {
          set(mapDataAtom, null);
          set(leftSideOpenAtom, true);
          set(dataListAtom, (current) => [...current, data]);
        }
      } else if (data.type === "image") {
        set(chatListAtom, (current) => {
          const lastItem = current[current.length - 1];

          if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text:
                  !Array.isArray(data.data) || data.data.length === 0
                    ? t("ViewModels.ChatTestViewModel.noImage")
                    : t("ViewModels.ChatTestViewModel.showImage"),
                data:
                  !Array.isArray(data.data) || data.data.length === 0
                    ? {
                        type: "image",
                        data: [
                          {
                            imgUrl:
                              "https://dashboard.dokgabi.ai/ieum_logo.png",
                            name: t("ViewModels.ChatTestViewModel.noImageName"),
                            desc: t("ViewModels.ChatTestViewModel.noImageDesc"),
                          },
                        ],
                      }
                    : data,
              },
            ];
          }
          return [
            ...current,
            {
              name: flow.name,
              image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                flow!.character_image!.file_path
              }`,
              text:
                !Array.isArray(data.data) || data.data.length === 0
                  ? t("ViewModels.ChatTestViewModel.noImage")
                  : t("ViewModels.ChatTestViewModel.showImage"),
              date: dayjs().format("MM.DD HH:mm"),
              data:
                !Array.isArray(data.data) || data.data.length === 0
                  ? {
                      type: "image",
                      data: [
                        {
                          imgUrl: "https://dashboard.dokgabi.ai/ieum_logo.png",
                          name: t("ViewModels.ChatTestViewModel.noImageName"),
                          desc: t("ViewModels.ChatTestViewModel.noImageDesc"),
                        },
                      ],
                    }
                  : data,
              chattingUser: "chatbot",
            },
          ];
        });
      } else if (data.type === "etc") {
        set(chatListAtom, (current) => {
          const lastItem = current[current.length - 1];

          if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text:
                  !Array.isArray(data.data) || data.data.length === 0
                    ? t("ViewModels.ChatTestViewModel.noEtc")
                    : t("ViewModels.ChatTestViewModel.showEtc"),
                data:
                  !Array.isArray(data.data) || data.data.length === 0
                    ? {
                        type: "image",
                        data: [
                          {
                            imgUrl:
                              "https://dashboard.dokgabi.ai/ieum_logo.png",
                            name: t("ViewModels.ChatTestViewModel.noEtcName"),
                            desc: t("ViewModels.ChatTestViewModel.noEtcDesc"),
                          },
                        ],
                      }
                    : data,
              },
            ];
          }
          return [
            ...current,
            {
              name: flow.name,
              image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                flow!.character_image!.file_path
              }`,
              text:
                !Array.isArray(data.data) || data.data.length === 0
                  ? t("ViewModels.ChatTestViewModel.noEtc")
                  : t("ViewModels.ChatTestViewModel.showEtc"),
              date: dayjs().format("MM.DD HH:mm"),
              data:
                !Array.isArray(data.data) || data.data.length === 0
                  ? {
                      type: "image",
                      data: [
                        {
                          imgUrl: "https://dashboard.dokgabi.ai/ieum_logo.png",
                          name: t("ViewModels.ChatTestViewModel.noEtcName"),
                          desc: t("ViewModels.ChatTestViewModel.noEtcDesc"),
                        },
                      ],
                    }
                  : data,
              chattingUser: "chatbot",
            },
          ];
        });
      } else {
        set(chatListAtom, (current) => {
          const lastItem = current[current.length - 1];

          if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text: t("ViewModels.ChatTestViewModel.responseError"),
              },
            ];
          }
          return [
            ...current,
            {
              name: flow.name,
              image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                flow!.character_image!.file_path
              }`,
              text: t("ViewModels.ChatTestViewModel.responseError"),
              date: dayjs().format("MM.DD HH:mm"),
              data: null,
              chattingUser: "chatbot",
            },
          ];
        });
      }
    };

    const accessToken = get(chattingAccessTokenAtom);

    const result = await requestChatbot(
      accessToken ?? "",
      inputValue,
      get(chatTestFlowAtom)?.chatbot_socket_parameter ?? "",
      character?.id ?? -1,
      userId,
      handleStreamMessage,
      handleJson
    );

    if (
      result === ResponseStatus.FORBIDDEN ||
      result === ResponseStatus.UNAUTHORIZED
    ) {
      set(chatListAtom, (current) => {
        const lastItem = current[current.length - 1];

        if (lastItem.text === "") {
          return [
            ...current.slice(0, -1),
            {
              ...lastItem,
              text: t("ViewModels.ChatTestViewModel.serviceEnd"),
            },
          ];
        } else {
          return [
            ...current,
            {
              name: flow.name,
              image: flow.character_image
                ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
                : "",
              text: t("ViewModels.ChatTestViewModel.serviceEnd"),
              data: null,
              date: dayjs().format("MM.DD HH:mm"),
              chattingUser: "chatbot",
            },
          ];
        }
      });
    } else if (result === ResponseStatus.ERROR) {
      set(chatListAtom, (current) => {
        const lastItem = current[current.length - 1];

        if (lastItem.text === "") {
          return [
            ...current.slice(0, -1),
            {
              ...lastItem,
              text: t("ViewModels.ChatTestViewModel.responseError"),
            },
          ];
        } else {
          return [
            ...current,
            {
              name: flow.name,
              image: flow.character_image
                ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
                : "",
              text: t("ViewModels.ChatTestViewModel.responseError"),
              data: null,
              date: dayjs().format("MM.DD HH:mm"),
              chattingUser: "chatbot",
            },
          ];
        }
      });
    }
  } else {
    const newMessage = {
      name: t("ViewModels.ChatTestViewModel.unknown"),
      image: "",
      text: inputValue,
      data: null,
      date: dayjs().format("MM.DD HH:mm"),
      chattingUser: "user",
      type: "text",
    };

    set(chatListAtom, (prevChatting) => [...prevChatting, newMessage]);
    set(chattingValueAtom, "");

    set(chatListAtom, (current) => [
      ...current,
      {
        name: t("ViewModels.ChatTestViewModel.unknown"),
        image: "",
        text: t("ViewModels.ChatTestViewModel.canTFind"),
        date: dayjs().format("MM.DD HH:mm"),
        chattingUser: "chatbot",
        data: null,
      },
    ]);
  }

  set(typeAvailableStateAtom, true);
  set(scrollStateAtom, false);
});
// -----------------------------------------------------------------------------------

export const handleKeyDown = atom(
  null,
  async (
    get,
    set,
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    t: any
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const inputValue = get(chattingValueAtom).trim();
      const userId = get(userIdAtom);

      if (inputValue === "" || !userId) return;

      set(typeAvailableStateAtom, false);
      const flow = get(chatTestFlowAtom);
      const character = get(chattingIntonationAtom);

      if (flow) {
        const newMessage = {
          name: flow.name,
          image: flow.character_image
            ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
            : "",
          text: inputValue,
          data: null,
          date: dayjs().format("MM.DD HH:mm"),
          chattingUser: "user",
          type: "text",
        };

        set(chatListAtom, (prevChatting) => [...prevChatting, newMessage]);
        set(chattingValueAtom, "");

        set(chatListAtom, (current) => [
          ...current,
          {
            name: flow.name,
            image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
              flow!.character_image!.file_path
            }`,
            text: "",
            date: dayjs().format("MM.DD HH:mm"),
            chattingUser: "chatbot",
            data: null,
          },
        ]);

        // 여기에서 backend랑 통신을 하도록 하자.
        const handleStreamMessage = (
          message: string | null,
          isFirst: boolean
        ) => {
          if (isFirst) set(scrollStateAtom, true);

          if (isFirst && message) {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: message,
                  },
                ];
              } else if (
                lastItem.chattingUser === "chatbot" &&
                lastItem.text !== ""
              ) {
                return [
                  ...current,
                  {
                    name: flow.name,
                    image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                      flow!.character_image!.file_path
                    }`,
                    text: message,
                    date: dayjs().format("MM.DD HH:mm"),
                    data: null,
                    chattingUser: "chatbot",
                  },
                ];
              }

              return current;
            });
          }

          if (!isFirst && message) {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem) {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: message,
                  },
                ];
              }

              return current;
            });
          }
        };

        const handleJson = (data: any) => {
          if (Array.isArray(data)) {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: t("ViewModels.ChatTestViewModel.responseError"),
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text: t("ViewModels.ChatTestViewModel.responseError"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data: null,
                  chattingUser: "chatbot",
                },
              ];
            });
          } else if (data.type === "map") {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? t("ViewModels.ChatTestViewModel.noMap")
                        : t("ViewModels.ChatTestViewModel.showMap"),
                    data: null,
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? t("ViewModels.ChatTestViewModel.noMap")
                      : t("ViewModels.ChatTestViewModel.showMap"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data: null,
                  chattingUser: "chatbot",
                },
              ];
            });

            set(leftSideOpenAtom, true);
            set(mapDataAtom, data);
            // set(dataListAtom, (current) => [...current, data]);
          } else if (
            data.type === "bar" ||
            data.type === "line" ||
            data.type === "scatter"
          ) {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? t("ViewModels.ChatTestViewModel.noChart")
                        : t("ViewModels.ChatTestViewModel.showChart"),
                    data: null,
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? t("ViewModels.ChatTestViewModel.noChart")
                      : t("ViewModels.ChatTestViewModel.showChart"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data: null,
                  chattingUser: "chatbot",
                },
              ];
            });

            if (!Array.isArray(data.data) || data.data.length === 0) {
              set(leftSideOpenAtom, false);
            } else {
              set(mapDataAtom, null);
              set(leftSideOpenAtom, true);
              set(dataListAtom, (current) => [...current, data]);
            }
          } else if (data.type === "image") {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? t("ViewModels.ChatTestViewModel.noImage")
                        : t("ViewModels.ChatTestViewModel.showImage"),
                    data:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? {
                            type: "image",
                            data: [
                              {
                                imgUrl:
                                  "https://dashboard.dokgabi.ai/ieum_logo.png",
                                name: t(
                                  "ViewModels.ChatTestViewModel.noImageName"
                                ),
                                desc: t(
                                  "ViewModels.ChatTestViewModel.noImageDesc"
                                ),
                              },
                            ],
                          }
                        : data,
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? t("ViewModels.ChatTestViewModel.noImage")
                      : t("ViewModels.ChatTestViewModel.showImage"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? {
                          type: "image",
                          data: [
                            {
                              imgUrl:
                                "https://dashboard.dokgabi.ai/ieum_logo.png",
                              name: t(
                                "ViewModels.ChatTestViewModel.noImageName"
                              ),
                              desc: t(
                                "ViewModels.ChatTestViewModel.noImageDesc"
                              ),
                            },
                          ],
                        }
                      : data,
                  chattingUser: "chatbot",
                },
              ];
            });
          } else if (data.type === "etc") {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? t("ViewModels.ChatTestViewModel.noEtc")
                        : t("ViewModels.ChatTestViewModel.showEtc"),
                    data:
                      !Array.isArray(data.data) || data.data.length === 0
                        ? {
                            type: "image",
                            data: [
                              {
                                imgUrl:
                                  "https://dashboard.dokgabi.ai/ieum_logo.png",
                                name: t(
                                  "ViewModels.ChatTestViewModel.noEtcName"
                                ),
                                desc: t(
                                  "ViewModels.ChatTestViewModel.noEtcDesc"
                                ),
                              },
                            ],
                          }
                        : data,
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? t("ViewModels.ChatTestViewModel.noEtc")
                      : t("ViewModels.ChatTestViewModel.showEtc"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data:
                    !Array.isArray(data.data) || data.data.length === 0
                      ? {
                          type: "image",
                          data: [
                            {
                              imgUrl:
                                "https://dashboard.dokgabi.ai/ieum_logo.png",
                              name: t("ViewModels.ChatTestViewModel.noEtcName"),
                              desc: t("ViewModels.ChatTestViewModel.noEtcDesc"),
                            },
                          ],
                        }
                      : data,
                  chattingUser: "chatbot",
                },
              ];
            });
          } else {
            set(chatListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem.chattingUser === "chatbot" && lastItem.text === "") {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: t("ViewModels.ChatTestViewModel.responseError"),
                  },
                ];
              }
              return [
                ...current,
                {
                  name: flow.name,
                  image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow!.character_image!.file_path
                  }`,
                  text: t("ViewModels.ChatTestViewModel.responseError"),
                  date: dayjs().format("MM.DD HH:mm"),
                  data: null,
                  chattingUser: "chatbot",
                },
              ];
            });
          }
        };

        const accessToken = get(chattingAccessTokenAtom);

        const result = await requestChatbot(
          accessToken ?? "",
          inputValue,
          get(chatTestFlowAtom)?.chatbot_socket_parameter ?? "",
          character?.id ?? -1,
          userId,
          handleStreamMessage,
          handleJson
        );

        if (
          result === ResponseStatus.FORBIDDEN ||
          result === ResponseStatus.UNAUTHORIZED
        ) {
          set(chatListAtom, (current) => {
            const lastItem = current[current.length - 1];

            if (lastItem.text === "") {
              return [
                ...current.slice(0, -1),
                {
                  ...lastItem,
                  text: t("ViewModels.ChatTestViewModel.serviceEnd"),
                },
              ];
            } else {
              return [
                ...current,
                {
                  name: flow.name,
                  image: flow.character_image
                    ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
                    : "",
                  text: t("ViewModels.ChatTestViewModel.serviceEnd"),
                  data: null,
                  date: dayjs().format("MM.DD HH:mm"),
                  chattingUser: "chatbot",
                },
              ];
            }
          });
        } else if (result === ResponseStatus.ERROR) {
          set(chatListAtom, (current) => {
            const lastItem = current[current.length - 1];

            if (lastItem.text === "") {
              return [
                ...current.slice(0, -1),
                {
                  ...lastItem,
                  text: t("ViewModels.ChatTestViewModel.responseError"),
                },
              ];
            } else {
              return [
                ...current,
                {
                  name: flow.name,
                  image: flow.character_image
                    ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
                    : "",
                  text: t("ViewModels.ChatTestViewModel.responseError"),
                  data: null,
                  date: dayjs().format("MM.DD HH:mm"),
                  chattingUser: "chatbot",
                },
              ];
            }
          });
        }
      } else {
        const newMessage = {
          name: t("ViewModels.ChatTestViewModel.unknown"),
          image: "",
          text: inputValue,
          data: null,
          date: dayjs().format("MM.DD HH:mm"),
          chattingUser: "user",
          type: "text",
        };

        set(chatListAtom, (prevChatting) => [...prevChatting, newMessage]);
        set(chattingValueAtom, "");

        set(chatListAtom, (current) => [
          ...current,
          {
            name: t("ViewModels.ChatTestViewModel.unknown"),
            image: "",
            text: t("ViewModels.ChatTestViewModel.canTFind"),
            date: dayjs().format("MM.DD HH:mm"),
            chattingUser: "chatbot",
            data: null,
          },
        ]);
      }

      set(typeAvailableStateAtom, true);
      set(scrollStateAtom, false);
    }
  }
);

// Function to split message based on sentence or flow change
const splitMessageBySentences = (message: string): string[] => {
  return message.split(/(?<=[.!?])\s+/); // Split by sentence-ending punctuation
};
