// ScatterPlot.tsx
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";
import useResizeObserver from "../useResizeObserver"; // 방금 만든 훅을 임포트

interface DataPoint {
  x: number; // x 값이 숫자
  y: number; // y 값이 숫자
  label: string; // 데이터 라벨
}

interface ScatterPlotProps {
  chartTitle: string;
  xLabel: string;
  yLabel: string;
  data: DataPoint[];
}

const ScatterPlot: React.FC<ScatterPlotProps> = ({
  chartTitle,
  xLabel,
  yLabel,
  data,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const { ref, dimensions } = useResizeObserver();

  useEffect(() => {
    if (!dimensions || data.length === 0) return; // 크기가 결정되지 않았거나 데이터가 없으면 차트를 그리지 않음

    const { width, height } = dimensions;

    // Chart dimensions
    const margin = { top: 50, right: 40, bottom: 60, left: 60 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Prevent negative dimensions
    if (innerWidth <= 0 || innerHeight <= 0) return;

    // Clear previous chart
    d3.select(svgRef.current).selectAll("*").remove();

    // Create SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`) // 반응형을 위한 viewBox 설정
      .attr("preserveAspectRatio", "xMidYMid meet"); // 비율 유지

    // Create chart group
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create scales
    const xScale = d3
      .scaleLinear()
      .domain([
        d3.min(data, (d) => d.x)! -
          (d3.max(data, (d) => d.x)! - d3.min(data, (d) => d.x)!) * 0.1,
        d3.max(data, (d) => d.x)! +
          (d3.max(data, (d) => d.x)! - d3.min(data, (d) => d.x)!) * 0.1,
      ])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([
        d3.min(data, (d) => d.y)! -
          (d3.max(data, (d) => d.y)! - d3.min(data, (d) => d.y)!) * 0.1,
        d3.max(data, (d) => d.y)! +
          (d3.max(data, (d) => d.y)! - d3.min(data, (d) => d.y)!) * 0.1,
      ])
      .range([innerHeight, 0])
      .nice();

    // Add X-axis
    const xAxis = d3.axisBottom(xScale).tickSize(-innerHeight).ticks(5);
    const xAxisGroup = chart
      .append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll("text")
      .attr("font-size", "10px")
      .attr("text-anchor", "end")
      .attr("transform", "rotate(-45)")
      .attr("dx", "-0.5em")
      .attr("dy", "0.5em");

    // Remove X-axis domain line
    chart.selectAll(".domain").remove();

    // Add X-axis label
    chart
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", innerHeight + margin.bottom - 15)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .text(xLabel);

    // Add Y-axis
    const yAxis = d3.axisLeft(yScale).tickSize(-innerWidth).ticks(5);
    const yAxisGroup = chart
      .append("g")
      .call(yAxis)
      .selectAll("text")
      .attr("font-size", "12px");

    // Remove Y-axis domain line
    chart.selectAll(".domain").remove();

    // Add Y-axis label
    chart
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -innerHeight / 2)
      .attr("y", -margin.left + 20)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .text(yLabel);

    // Add chart title
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", margin.top / 2 + 10)
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .text(chartTitle);

    // **Optional: Remove Grid Lines**
    // 만약 그리드 라인도 제거하고 싶으시면 아래 코드를 주석 처리하거나 제거하세요.

    chart
      .selectAll(".grid-line-horizontal")
      .data(yScale.ticks())
      .enter()
      .append("line")
      .attr("class", "grid-line-horizontal")
      .attr("x1", 0)
      .attr("x2", innerWidth)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#e0e0e0")
      .attr("stroke-dasharray", "4");

    chart
      .selectAll(".grid-line-vertical")
      .data(xScale.ticks())
      .enter()
      .append("line")
      .attr("class", "grid-line-vertical")
      .attr("y1", 0)
      .attr("y2", innerHeight)
      .attr("x1", (d) => xScale(d)!)
      .attr("x2", (d) => xScale(d)!)
      .attr("stroke", "#e0e0e0")
      .attr("stroke-dasharray", "4");

    // Add points with animation and border
    chart
      .selectAll(".dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("cx", (d) => xScale(d.x))
      .attr("cy", (d) => yScale(d.y))
      .attr("r", 0) // 초기 반지름을 0으로 설정
      .style("fill", process.env.REACT_APP_MAIN_COLOR || "#32cd32") // 점 색상 변경 가능 (예: 라임 그린 색상)
      .style("stroke", "white") // 점의 테두리 색상 설정
      .style("stroke-width", "1px") // 점의 테두리 두께 설정
      .transition()
      .duration(1000)
      .attr("r", 6) // 애니메이션을 통해 반지름을 6으로 증가
      .delay((d, i) => i * 100); // 각 점마다 지연 시간 추가

    // Add labels for points
    chart
      .selectAll(".label")
      .data(data)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(d.x)! + 8)
      .attr("y", (d) => yScale(d.y) + 4)
      .text((d) => d.label)
      .style("font-size", "12px")
      .style("fill", "#555");

    // Tooltip 생성
    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("background", "rgba(0, 0, 0, 0.6)")
      .style("color", "#fff")
      .style("padding", "5px 10px")
      .style("border-radius", "4px")
      .style("font-size", "12px");

    // 점에 마우스 이벤트 추가
    chart
      .selectAll(".dot")
      .on("mouseover", (event, d: any) => {
        tooltip
          .style("visibility", "visible")
          .html(`X: ${d.x}<br>Y: ${d.y}`)
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        tooltip.style("visibility", "hidden");
      });

    // 컴포넌트 언마운트 시 툴팁 제거
    return () => {
      tooltip.remove();
    };
  }, [chartTitle, xLabel, yLabel, data, dimensions]);

  return (
    <Box
      ref={ref}
      sx={{
        width: "100%",
        height: "100%",
        padding: "15px",
        borderRadius: "5px",
        background: "white",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <svg
        ref={svgRef}
        style={{ flexGrow: 1 }}
        viewBox={`0 0 ${dimensions?.width || 600} ${dimensions?.height || 400}`}
        preserveAspectRatio="xMidYMid meet"
      ></svg>
    </Box>
  );
};

export default ScatterPlot;
