import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  ChattingProps,
  chattingTypeAtom,
  ChatType,
  searchValueAtom,
} from "../../../../Atoms/ChatTestAtom";
import { useTranslation } from "react-i18next";
import { CopyAll } from "@mui/icons-material";
import Lottie from "react-lottie";
import { useAtom } from "jotai";
import LottieData from "./Animation/Animation - 1723387593175.json";
import { handleImageClickAtom } from "../../../../ViewModels/ChatTestViewModel";
import { FiCopy } from "react-icons/fi";

export function InteractionChatting({
  name,
  image,
  text,
  date,
  data,
  chattingUser,
}: ChattingProps) {
  const { t } = useTranslation();
  const [chatType] = useAtom(chattingTypeAtom);
  const [searchValue] = useAtom(searchValueAtom);

  const [, handleVirtualTryOn] = useAtom(handleImageClickAtom);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const boxRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [draggedDistance, setDraggedDistance] = useState(0);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!boxRef.current) return;
    setIsDragging(true);
    setStartX(e.clientX);
    setScrollLeft(boxRef.current.scrollLeft);
    setDraggedDistance(0); // 드래그 거리 초기화
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !boxRef.current) return;
    const x = e.clientX;
    const walk = (x - startX) * 1.2; // 드래그 속도 조절
    setDraggedDistance(Math.abs(walk)); // 이동 거리 계산
    boxRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    setIsDragging(false); // 드래그 종료
  };

  const handleCardClick = (image: any) => {
    if (draggedDistance > 5) {
      // 드래그로 인해 클릭 무시
      return;
    }
    // 클릭 동작
    handleVirtualTryOn(image, t);
  };

  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(
        () => {
          // 성공 시 툴팁을 보여주고 일정 시간 후에 사라지게 함
          setTooltipOpen(true);
          setTimeout(() => {
            setTooltipOpen(false);
          }, 1000); // 2초 동안 툴팁을 표시함
        },
        (err) => {
          console.error("Failed to copy text:", err);
        }
      );
    } else {
      // 구형 브라우저나 HTTP 환경에서 clipboard API가 없을 경우
      // 예시: document.execCommand('copy') 방식 등
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy"); // 오래된 방법(일부 브라우저에서만 작동)
      document.body.removeChild(textarea);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000); // 2초 동안 툴팁을 표시함
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // 검색어 하이라이트 처리 함수
  const highlightText = (children: any) => {
    if (!searchValue || chatType === ChatType.ASK) return children;

    // React.Children.map을 사용하여 children을 순회
    return React.Children.map(children, (child) => {
      // 문자열인지 확인 (텍스트 노드만 처리)
      if (typeof child === "string") {
        const regex = new RegExp(`(${searchValue})`, "gi");
        return child.split(regex).map((part, index) =>
          regex.test(part) ? (
            <span
              key={`${index}-highlight-search-field-interaction`}
              className="highlight-search-field"
              style={{ backgroundColor: "yellow", color: "black" }}
            >
              {part}
            </span>
          ) : (
            part
          )
        );
      }
      // 문자열이 아닌 경우 (JSX 요소) 그대로 반환
      return child;
    });
  };

  if (chattingUser === "chatbot" && data === null) {
    return (
      <Stack
        // direction={"row"}
        sx={{
          gap: "10px",
          width: "100%",
          flexDirection: "row" /* 모바일 */,
          "& .only-in-mobile-view": {
            display: "none",
          },
          "& .only-in-web-view": {
            display: "block",
          },

          "@media (max-width: 768px)": {
            flexDirection: "column",
            "& .only-in-web-view": {
              display: "none",
            },
            "& .only-in-mobile-view": {
              display: "block",
            },
          },
        }}
      >
        <Avatar className="only-in-web-view" src={image} />

        <Stack direction={"row"} gap={"10px"} sx={{ alignItems: "center" }}>
          <Avatar className="only-in-mobile-view" src={image} />
          <Typography className="only-in-mobile-view">{name}</Typography>
        </Stack>
        <Stack
          sx={{
            gap: "5px",
            maxWidth: "70%",
            "@media (max-width: 1024px)": {
              maxWidth: "85%",
            },

            /* 모바일 */
            "@media (max-width: 768px)": {
              maxWidth: "100%",
            },
          }}
        >
          <Typography className="only-in-web-view">{name}</Typography>
          <Box
            sx={{
              background: "#ececec",
              borderRadius: "5px",
              padding: "15px",

              "@media (max-width: 1024px)": {
                padding: "7px",
              },

              /* 모바일 */
              "@media (max-width: 768px)": {
                padding: "3px",
              },
            }}
          >
            {text !== "" ? (
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  p: ({ node, children }) => (
                    <p
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </p>
                  ),
                  h1: ({ node, children }) => (
                    <h1
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h1>
                  ),
                  h2: ({ node, children }) => (
                    <h2
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h2>
                  ),
                  h3: ({ node, children }) => (
                    <h3
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h3>
                  ),
                  h4: ({ node, children }) => (
                    <h4
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h4>
                  ),
                  h5: ({ node, children }) => (
                    <h5
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h5>
                  ),
                  h6: ({ node, children }) => (
                    <h6
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </h6>
                  ),
                  ul: ({ node, children }) => (
                    <ul
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </ul>
                  ),
                  ol: ({ node, children }) => (
                    <ol
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </ol>
                  ),
                  li: ({ node, children }) => (
                    <li
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </li>
                  ),
                  table: ({ node, children }) => (
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        margin: "20px 0",
                        backgroundColor: "#fff", // 바탕색 하얀색 설정
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </table>
                  ),
                  tbody: ({ node, children }) => (
                    <tbody>{highlightText(children)}</tbody>
                  ),
                  tr: ({ node, children }) => (
                    <tr style={{ borderBottom: "1px solid #ddd" }}>
                      {highlightText(children)}
                    </tr>
                  ),
                  th: ({ node, children }) => (
                    <th
                      style={{
                        backgroundColor: "#f2f2f2",
                        textAlign: "left",
                        borderBottom: "2px solid #ddd",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </th>
                  ),
                  td: ({ node, children }) => (
                    <td
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#fff", // 바탕색 하얀색 설정
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </td>
                  ),
                  blockquote: ({ node, children }) => (
                    <blockquote
                      style={{
                        width: "100%",
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </blockquote>
                  ),
                  code: ({ node, children }) => (
                    <code
                      style={{
                        // overflowX: "auto",
                        margin: "10px",
                        wordBreak: "break-word", // 긴 단어 줄 바꿈
                        overflowWrap: "break-word", // 줄 바꿈 허용
                      }}
                    >
                      {highlightText(children)}
                    </code>
                  ),
                  pre: ({ node, children }) => (
                    <pre
                      style={{
                        margin: "10px",
                        wordBreak: "break-word", // 줄 바꿈
                        overflowX: "auto", // 가로 스크롤 허용
                        whiteSpace: "pre-wrap", // 줄 바꿈 처리
                        overflowWrap: "break-word", // 줄 바꿈 허용
                      }}
                    >
                      {highlightText(children)}
                    </pre>
                  ),
                  em: ({ node, children }) => (
                    <em
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </em>
                  ),
                  strong: ({ node, children }) => (
                    <strong
                      style={{
                        margin: "10px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </strong>
                  ),
                  a: ({ node, href, children }) => (
                    <a
                      href={href}
                      target="_blank" // 새 창에서 열기
                      rel="noopener noreferrer" // 보안 관련 속성 추가
                      style={{
                        color: "#1a0dab",
                        textDecoration: "underline",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {highlightText(children)}
                    </a>
                  ),
                  img: ({ node, src, alt }) => (
                    <img
                      src={src}
                      alt={alt}
                      style={{
                        maxWidth: "100%", // 이미지가 부모 요소 너비를 넘지 않도록
                        border: "2px solid #ddd", // 얇은 테두리 추가
                        borderRadius: "8px", // 모서리를 둥글게
                        padding: "5px", // 이미지와 테두리 사이에 약간의 패딩 추가
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 가벼운 그림자 추가
                        margin: "20px 0", // 상하 여백 추가
                      }}
                    />
                  ),
                }}
              >
                {text}
              </ReactMarkdown>
            ) : (
              <Lottie
                options={defaultOptions}
                height={40}
                width={100}
                isClickToPauseDisabled={true}
              />
            )}
          </Box>
          <Stack
            direction={"row"}
            sx={{ width: "100%", justifyContent: "space-between" }}
          >
            <Typography fontSize={"12px"} color={"#8f8f8f"}>
              {date}
            </Typography>
            {/* 툴팁이 있는 아이콘 버튼 */}
            <Tooltip
              title={t("Components.ChatTest.InteractionCenter.Chatting.copy")} // 툴팁 내용
              open={tooltipOpen} // 상태에 따라 툴팁을 표시
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
            >
              <IconButton onClick={handleCopy}>
                <CopyAll />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  const renderValue = (key: string, value: any) => {
    if (key === "imgUrl") {
      return (
        <CardMedia
          component="img"
          src={value}
          alt={value}
          sx={{
            maxHeight: "200px",
            maxWidth: "100%",
            objectFit: "contain",
            borderRadius: "10px 10px 0 0",
          }}
        />
      );
    }

    if (Array.isArray(value)) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            paddingLeft: "16px",
          }}
        >
          {value.map((item, idx) => (
            <React.Fragment key={idx}>
              <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                - {JSON.stringify(item, null, 2)}
              </Typography>
              {idx < value.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Box>
      );
    }

    if (typeof value === "object" && value !== null) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            paddingLeft: "16px",
          }}
        >
          {Object.keys(value).map((subKey, idx) => (
            <React.Fragment key={subKey}>
              <Typography variant="body2" color="textSecondary">
                {subKey}:
              </Typography>
              <Typography
                variant="body1"
                sx={{ paddingLeft: "8px", wordBreak: "break-word" }}
              >
                {JSON.stringify(value[subKey], null, 2)}
              </Typography>
              {idx < Object.keys(value).length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Box>
      );
    }

    return (
      <Typography
        variant="body1"
        sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
      >
        {value}
      </Typography>
    );
  };

  if (chattingUser === "chatbot" && data !== null) {
    return (
      <Stack
        // direction={"row"}
        sx={{
          gap: "10px",
          width: "100%",
          flexDirection: "row" /* 모바일 */,
          "& .only-in-mobile-view": {
            display: "none",
          },
          "& .only-in-web-view": {
            display: "block",
          },

          "@media (max-width: 768px)": {
            flexDirection: "column",
            "& .only-in-web-view": {
              display: "none",
            },
            "& .only-in-mobile-view": {
              display: "block",
            },
          },
        }}
      >
        <Avatar className="only-in-web-view" src={image} />

        <Stack direction={"row"} gap={"10px"} sx={{ alignItems: "center" }}>
          <Avatar className="only-in-mobile-view" src={image} />
          <Typography className="only-in-mobile-view">{name}</Typography>
        </Stack>
        <Stack
          sx={{
            gap: "5px",
            maxWidth: "70%",
            "@media (max-width: 1024px)": {
              maxWidth: "85%",
            },

            /* 모바일 */
            "@media (max-width: 768px)": {
              maxWidth: "100%",
            },
          }}
        >
          <Typography className="only-in-web-view">{name}</Typography>
          <Box
            sx={{
              background: "#ececec",
              borderRadius: "5px",
              padding: "15px",

              "@media (max-width: 1024px)": {
                padding: "7px",
              },

              /* 모바일 */
              "@media (max-width: 768px)": {
                padding: "3px",
              },
            }}
          >
            <Typography className="only-in-web-view">{text}</Typography>
          </Box>

          {/* carousel 넣기 */}
          <Box
            ref={boxRef}
            sx={{
              background: "#ececec",
              borderRadius: "5px",
              padding: "15px",
              display: "flex",
              gap: "10px",
              overflowX: "auto",
              maxWidth: "100%",
              cursor: isDragging ? "grabbing" : "grab",
              userSelect: "none", // 텍스트 선택 방지
              "&::-webkit-scrollbar": {
                height: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#ccc",
                borderRadius: "3px",
              },
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUpOrLeave}
            onMouseLeave={handleMouseUpOrLeave}
          >
            {"data" in data && "type" in data ? (
              data.type === "image" ? (
                data.data?.map(
                  (image: any, index: number) =>
                    "imgUrl" in image &&
                    "desc" in image &&
                    "name" in image && (
                      <Card
                        key={`image-carousel-${image.imgUrl}-${index}`}
                        sx={{
                          minWidth: "200px",
                          maxWidth: "300px",
                          flex: "0 0 auto",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCardClick(image)}
                      >
                        <CardMedia
                          component="img"
                          src={image.imgUrl}
                          alt={image.name}
                          onDragStart={(e) => e.preventDefault()} // 이미지 드래그 방지
                          sx={{
                            maxHeight: "200px",
                            maxWidth: "100%",
                            objectFit: "contain",
                            borderRadius: "10px 10px 0 0",
                          }}
                        />
                        <CardContent>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "#333",
                            }}
                          >
                            {image.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#666",
                              marginTop: "4px",
                            }}
                          >
                            {image.desc}
                          </Typography>
                        </CardContent>
                      </Card>
                    )
                )
              ) : data.type === "etc" ? (
                data.data?.map((item: any, index: number) => (
                  <Card
                    sx={{
                      minWidth: "200px",
                      maxWidth: "300px",
                      flex: "0 0 auto",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      cursor: "pointer",
                      boxSizing: "border-box",
                      padding: "16px",
                    }}
                  >
                    <CardHeader
                      title={
                        <Typography
                          color={process.env.REACT_APP_MAIN_COLOR}
                          fontWeight={"bold"}
                          fontSize={"20px"}
                        >
                          {Object.keys(item).length > 0
                            ? item[Object.keys(item)[0]]
                            : ""}
                        </Typography>
                      }
                      subheader={
                        Object.keys(item).length > 1
                          ? item[Object.keys(item)[1]]
                          : ""
                      }
                      action={
                        <IconButton onClick={handleCopy}>
                          <FiCopy />
                        </IconButton>
                      }
                    />
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                      }}
                    >
                      {Object.keys(item)
                        .slice(2)
                        .map((key) => (
                          <Box key={key}>
                            {key !== "imgUrl" && (
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  fontWeight: "bold",
                                  wordBreak: "break-word",
                                }}
                              >
                                {key}
                              </Typography>
                            )}
                            {renderValue(key, item[key])}
                          </Box>
                        ))}
                    </CardContent>
                  </Card>
                ))
              ) : null
            ) : (
              <Typography fontSize={"14px"}>
                {t("Components.ChatTest.InteractionCenter.Chatting.error")}
              </Typography>
            )}
          </Box>

          <Stack
            direction={"row"}
            sx={{ width: "100%", justifyContent: "space-between" }}
          >
            <Typography fontSize={"12px"} color={"#8f8f8f"}>
              {date}
            </Typography>
            {/* 툴팁이 있는 아이콘 버튼 */}
            <Tooltip
              title={t("Components.ChatTest.InteractionCenter.Chatting.copy")} // 툴팁 내용
              open={tooltipOpen} // 상태에 따라 툴팁을 표시
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
            >
              <IconButton onClick={handleCopy}>
                <CopyAll />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  // user 채팅
  return (
    <Stack
      direction="row"
      sx={{
        gap: "10px",
        width: "100%",
        justifyContent: "end", // 오른쪽 정렬
        // 모바일에서 세로 배치
        "@media (max-width: 768px)": {
          flexDirection: "column",
          alignItems: "flex-end",
        },
      }}
    >
      <Stack
        sx={{
          gap: "5px",
          maxWidth: "70%", // 데스크톱 기준 최대 너비
          "@media (max-width: 1024px)": {
            maxWidth: "85%",
          },
          "@media (max-width: 768px)": {
            maxWidth: "100%",
          },
          textAlign: "end",
        }}
      >
        <Box
          sx={{
            // 핵심: 내용 길이에 맞춰 폭이 늘어나되, 최대 100%까지만
            display: "inline-block",
            // 혹은 width: "fit-content",
            maxWidth: "100%",
            background: process.env.REACT_APP_MAIN_COLOR,
            color: "white",
            borderRadius: "5px",
            padding: "15px",
            whiteSpace: "pre-wrap", // \n을 실제 줄바꿈으로 처리
            wordBreak: "break-word", // 긴 단어도 줄바꿈 허용
            "@media (max-width: 1024px)": {
              padding: "7px",
            },
            "@media (max-width: 768px)": {
              padding: "3px",
            },
          }}
        >
          {text.split("\n").map((line, index) => (
            <span key={`${index}-interaction-span-text`}>
              {highlightText(line)}
              <br />
            </span>
          ))}
        </Box>
        <Typography fontSize="12px" color="#8f8f8f">
          {date}
        </Typography>
      </Stack>
    </Stack>
  );
}
