import React from "react";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import { ChatTest } from "./ChatTest";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/:slug" element={<ChatTest />}></Route>
    </Routes>
  );
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <AppRouter />,
  },
]);

export default router;
