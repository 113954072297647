import { AllFlowProps, ChattingProps } from "../Atoms/ChatTestAtom";
import { ChattingEnum } from "../Enum/ChattingEnum";
import { ResponseStatus } from "../Enum/NetworkEnum";

export async function getAllaboutFlow(
  apiKey: string,
  user_id: string,
  language: string
): Promise<AllFlowProps | number> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      apiKey: apiKey,
      user: user_id,
      language: language,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getAllaboutFlow`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else {
    return ChattingEnum.SOMETHING_WRONG;
  }
}

export async function requestChatbot(
  access: string,
  input_message: string,
  flow_socket_name: string,
  character: number,
  user_id: string,
  handleStreamMessage: (message: string | null, isFirst: boolean) => void,
  handleJson: (data: any) => void
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      flow_socket_name: flow_socket_name,
      input_message: input_message,
      user_id: user_id,
      character: character,
    }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/chatbot`,
      requestOptions
    );

    if (response.status === 401) {
      return ResponseStatus.UNAUTHORIZED;
    } else if (response.status === 403) {
      return ResponseStatus.FORBIDDEN;
    } else if (!response.ok) {
      return ResponseStatus.ERROR;
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let isFirst = true;
    let message = "";
    // json 조립용
    let buffer = "";

    while (true) {
      const { done, value } = await reader!.read();

      if (done) {
        // 여기에 음성을 출력하는 기능 추가
        break;
      }

      const chunk = decoder.decode(value, { stream: true });

      buffer += chunk;

      const jsonPattern = /JSON:(.*?)?:JSON/;
      const match = buffer.match(jsonPattern);

      if (match) {
        // JSON 문자열 추출 (JSON: 와 :JSON 제거)
        const jsonString = match[1];
        const json = JSON.parse(jsonString);

        // JSON 데이터를 제거한 나머지 텍스트를 리스트로 분리
        const splitParts = buffer.split(match[0]); // JSON 앞뒤로 분리
        const resultList = [splitParts[0] || "", splitParts[1] || ""]; // 앞뒤를 보장

        // 각 부분 처리
        if (resultList[0].trim()) {
          message += resultList[0];
          handleStreamMessage(message, isFirst);
          isFirst = false;
        }

        // JSON 데이터를 handleJson으로 전달
        handleJson(json);

        if (resultList[1].trim()) {
          message = resultList[1];
          isFirst = true;
          handleStreamMessage(message, isFirst);
          isFirst = false;
        }

        buffer = "";
      } else if (buffer.includes("JSON:")) {
        continue;
      } else {
        message += buffer;
        handleStreamMessage(message, isFirst);

        isFirst = false;
        buffer = "";
      }
    }
  } catch (error) {
    return ResponseStatus.ERROR;
  }
}

export async function virtualTryOn(
  access: string,
  category: string,
  cloth_url: string,
  model_image: string,
  cloth_name: string
): Promise<null | string | number> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      category: category,
      cloth_url: cloth_url,
      model_image: model_image,
      cloth_name: cloth_name,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/virtualTryOn`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}
