import { atom } from "jotai";
import {
  DokgabiFlowProps,
  DokgabiFrontElementProps,
  DokgabiIntonationProps,
} from "../Models/DokgabiFlow";

export const currentSideBarAtom = atom("basic");

// Error 상태 관리 atom
export const errorAtom = atom<string | null>("waiting");

// ---------------- 통합(chat.dokgabi.ai 와 동일하게 가져갈것들)
export interface ChattingProps {
  name: string;
  image: string;
  text: string;
  date: string;
  data: any;
  chattingUser: string;
}

export interface AllFlowProps {
  access_token: string;
  flow: DokgabiFlowProps;
  front_element: DokgabiFrontElementProps[];
  tone: DokgabiIntonationProps[];
  chat: ChattingProps[];
}

/// 상태 관련
// 처음 들어와서 초기화가 끝났는 가에 관한 것
export const isInitializeFinishAtom = atom(false);
//보내는 중인지 아닌지 체크하는 것
export const typeAvailableStateAtom = atom(true);
//상호작용으로 만들어지는게 만들어지고 있는지 체크하는 것
export const interactionmakingAtom = atom(false);

export const scrollStateAtom = atom(false);
export const isOpenRecommendQuestionAtom = atom(true);

// input 관련
export const userIdAtom = atom<string | null>(null);
export const userLanguageAtom = atom<string | null>(null);
export enum ChatType {
  ASK = 0,
  SEARCH = 1,
}

export const chattingTypeAtom = atom<ChatType>(ChatType.ASK);
export const chattingValueAtom = atom("");
export const searchValueAtom = atom("");
export const currentSearchIndexAtom = atom(0);
export const inputFileValueAtom = atom<
  | {
      file: File;
      extension: string;
    }[]
  | null
>(null);

// 처음에 가져와야 될 것
// access token
export const chattingAccessTokenAtom = atom<string | null>(null);
export const chatTestFlowAtom = atom<DokgabiFlowProps | null>(null);
export const frontElementListAtom = atom<DokgabiFrontElementProps[]>([]);
export const toneListAtom = atom<DokgabiIntonationProps[]>([]);
export const chatListAtom = atom<ChattingProps[]>([]); // 이건 챗봇에 의해서 생성되는 것과도 관련이 있음

// 챗봇에 의해서 생성되는 것 관련
export const chatbotVoiceListAtom = atom<string[]>([]);
export const interactionListAtom = atom<unknown[]>([]);

// 말투
export const chattingIntonationAtom = atom<DokgabiIntonationProps | null>(null);

// 기타
// 입력창에서 검색 하는건지 챗봇 문장 입력하는건지 판단하는 ref
export const isChattingAskAtom = atom<HTMLElement | null>(null);

// 추천질문 관련
export const recommendCurrentPageAtom = atom(0);

// 상호작용 관련
export const dataWaitingListAtom = atom<number[]>([]);

export const dataListAtom = atom<
  {
    type: string;
    chart_title?: string;
    x_label?: string;
    y_label?: string;
    data: any;
  }[]
>([]);

export const mapDataAtom = atom<any | null>(null);

export const leftSideOpenAtom = atom(false);
