import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  CircularProgress,
} from "@mui/material";
import { useAtom } from "jotai";
import BarChart from "./Bar";
import LineChart from "./Line";
import ScatterPlot from "./Scatter";
import {
  dataListAtom,
  dataWaitingListAtom,
  mapDataAtom,
} from "../../../../Atoms/ChatTestAtom";
import { Map } from "./Map";
import { useTranslation } from "react-i18next";

export function LeftSide() {
  const [dataList] = useAtom(dataListAtom);
  const [mapData] = useAtom(mapDataAtom);
  const [dataWaitingList] = useAtom(dataWaitingListAtom);

  const { t } = useTranslation();

  return (
    <>
      {mapData && (
        <Box sx={{ height: "100vh", width: "100%" }}>
          <Map data={mapData.data} />
        </Box>
      )}
      <Grid container spacing={2} sx={{ overflowY: "auto" }} padding={5}>
        {dataList.map((data, index) => (
          <Grid
            item
            xs={12} // 모바일: 1개씩
            sm={6} // 태블릿: 2개씩
            md={6} // 데스크톱: 3개씩
            key={`${index}-data-list`}
            sx={{ height: 350 }}
          >
            <Card
              key={`${index}-data-list-card`}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {data.type === "virtual" ? (
                <CardMedia
                  component="img"
                  sx={{
                    height: "350px",
                    // maxWidth: "100%",
                    objectFit: "contain",
                    border: "none",
                  }}
                  image={data.data.output as unknown as string}
                  alt="virtual try on"
                />
              ) : data.type === "bar" ? (
                <BarChart
                  chartTitle={data.chart_title ?? ""}
                  xLabel={data.x_label ?? ""}
                  yLabel={data.y_label ?? ""}
                  data={data.data}
                />
              ) : data.type === "line" ? (
                <LineChart
                  chartTitle={data.chart_title ?? ""}
                  xLabel={data.x_label ?? ""}
                  yLabel={data.y_label ?? ""}
                  data={data.data}
                />
              ) : data.type === "scatter" ? (
                <ScatterPlot
                  chartTitle={data.chart_title ?? ""}
                  xLabel={data.x_label ?? ""}
                  yLabel={data.y_label ?? ""}
                  data={data.data}
                />
              ) : null}
            </Card>
          </Grid>
        ))}
        {dataWaitingList.map((data, index) => (
          <Grid
            item
            xs={12} // 모바일: 1개씩
            sm={6} // 태블릿: 2개씩
            md={6} // 데스크톱: 3개씩
            key={`${index}-data-waiting`}
            sx={{ height: 350 }}
          >
            <Card
              key={`${index}-data-waiting-list-card`}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
              />
              <Typography sx={{ fontSize: "12px", color: "#8f8f8f" }}>
                {t("Components.ChatTest.InteractionCenter.LeftSide.inProgress")}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
