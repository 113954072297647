import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { useAtom } from "jotai";
import {
  chatListAtom,
  chattingValueAtom,
  isInitializeFinishAtom,
  isOpenRecommendQuestionAtom,
  leftSideOpenAtom,
  scrollStateAtom,
  searchValueAtom,
  typeAvailableStateAtom,
} from "../../../Atoms/ChatTestAtom";
import { InteractionChatInput } from "./ChatInput";
import { InteractionChatting } from "./Chatting";
import { InteractionRecommendQuestion } from "./RecommendQuestion";
import { InteractionRecommendQuestionButton } from "./RecommendQuestionButton";
import { debounce } from "lodash";
import { ArrowDownward, Close, KeyboardArrowLeft } from "@mui/icons-material";
import { LeftSide } from "./LeftSide";
import { useTranslation } from "react-i18next";

export function InteractionCenter() {
  const [chatting] = useAtom(chatListAtom);
  const [scrollState, setScrollState] = useAtom(scrollStateAtom);
  const [typeAvailableState] = useAtom(typeAvailableStateAtom);
  const [inputValue] = useAtom(chattingValueAtom);
  const [searchValue] = useAtom(searchValueAtom);
  const [isOpenRecommendQuestion] = useAtom(isOpenRecommendQuestionAtom);
  const [leftSideOpen, setLeftSideOpen] = useAtom(leftSideOpenAtom);

  // 스크롤을 위한 ref 생성
  const boxRef = useRef<HTMLDivElement | null>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const [buttonOffset, setButtonOffset] = useState<number | null>(null);
  const [isInitializeFinish] = useAtom(isInitializeFinishAtom);

  const { t } = useTranslation();

  useEffect(() => {
    if (bottomRef.current && isInitializeFinish) {
      setTimeout(() => {
        if (bottomRef.current)
          bottomRef.current.scrollIntoView({ behavior: "auto" });
      }, 500);
    }
  }, [isInitializeFinish]);

  useEffect(() => {
    if (boxRef.current && bottomRef.current) {
      const container = boxRef.current;
      const distanceFromBottom =
        container.scrollHeight - container.scrollTop - container.clientHeight;

      // 밑에서 50px 이내일 경우 자동 스크롤
      if (distanceFromBottom <= 400) {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [chatting]);

  useEffect(() => {
    const updateButtonPosition = () => {
      if (boxRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = boxRef.current;

        if (scrollTop + clientHeight === scrollHeight) {
          setButtonOffset(null);
        } else {
          setButtonOffset(scrollTop + clientHeight - 40);
        }
      }
    };

    // 디바운스 적용 (300ms 후 실행)
    const debouncedUpdateButtonPosition = debounce(updateButtonPosition, 300);

    const box = boxRef.current;
    if (box) {
      box.addEventListener("scroll", debouncedUpdateButtonPosition);
    }

    updateButtonPosition();

    return () => {
      if (box) {
        box.removeEventListener("scroll", debouncedUpdateButtonPosition);
      }
      debouncedUpdateButtonPosition.cancel(); // 디바운스 정리
    };
  }, [chatting, inputValue, searchValue, isOpenRecommendQuestion]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <Stack
        direction={"column"}
        sx={{
          width: leftSideOpen ? "40%" : "100%",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "end",
            padding: "5px",
            boxSizing: "border-box",
          }}
        >
          {!leftSideOpen && (
            <IconButton onClick={() => setLeftSideOpen((current) => !current)}>
              <KeyboardArrowLeft />
            </IconButton>
          )}
        </Stack>
        <Box
          ref={boxRef}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexGrow: 1,
            overflowY: "auto",
            padding: "20px",
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          <Stack
            sx={{
              width: "60%",
              gap: "40px",
              "@media(max-width: 1024px)": {
                width: "80%",
              },
              "@media(max-width: 768px)": {
                width: "100%",
              },
            }}
          >
            {chatting.map((chat, index) => (
              <InteractionChatting
                key={`interaction-chatting-${index}`}
                name={chat.name}
                image={chat.image}
                text={chat.text}
                date={chat.date}
                chattingUser={chat.chattingUser}
                data={chat.data}
              />
            ))}
            <div ref={bottomRef} />
          </Stack>
          {buttonOffset && (
            <Button
              sx={{
                position: "absolute",
                left: "50%", // 부모의 수평 중앙 기준
                top: `${buttonOffset}px`, // 스크롤 하단에 붙도록 설정
                transform: "translateX(-50%)", // 버튼 너비의 절반만큼 왼쪽으로 이동
                zIndex: 100,
                height: "32px",
                width: "auto",
                borderRadius: "30px",
                border: "1px solid rgb(175, 175, 175)",
                background: "white",
                whiteSpace: "nowrap",
                color: "#8f8f8f",
                "&:hover": {
                  background: "white",
                },
              }}
              onClick={() => {
                if (bottomRef.current)
                  bottomRef.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {!typeAvailableState && !scrollState ? (
                <Typography>
                  {t("Components.ChatTest.InteractionCenter.waiting")}
                </Typography>
              ) : !typeAvailableState && scrollState ? (
                <Typography>
                  {t("Components.ChatTest.InteractionCenter.generating")}
                </Typography>
              ) : null}
              <ArrowDownward />
            </Button>
          )}
        </Box>
        <Stack
          sx={{
            width: "60%",
            marginTop: "10px",
            marginBottom: `10px`,
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            gap: `5px`,
            "@media(max-width: 1024px)": {
              width: "80%",
            },
            "@media(max-width: 768px)": {
              width: "100%",
            },
          }}
          direction="column"
        >
          <InteractionChatInput />
          <Typography sx={{ color: "#8f8f8f", fontSize: "13px" }}>
            {t("Components.ChatTest.InteractionCenter.notice")}
          </Typography>
          <InteractionRecommendQuestion />

          <InteractionRecommendQuestionButton />
        </Stack>
      </Stack>
      <Box
        sx={{
          transition: "width 300ms ease-out",
          boxSizing: "border-box",
          backgroundColor: "#d9d9d9",
          width: leftSideOpen ? "60%" : 0,
          overflowY: "auto",
          height: "100%",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "end",
            padding: "5px",
            boxSizing: "border-box",
          }}
        >
          {leftSideOpen && (
            <IconButton onClick={() => setLeftSideOpen((current) => !current)}>
              <Close sx={{ color: "black" }} />
            </IconButton>
          )}
        </Stack>
        <LeftSide />
      </Box>
    </Box>
  );
}
