import { Box, IconButton, InputBase, Menu } from "@mui/material";
import styled from "styled-components";

export const SelectWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;

  .MuiIconButton-root {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.625rem;
    background-color: #e5e8eb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #e0e0e0;
    }
  }

  .DropdownIcon {
    margin-left: 0.3125rem;
  }
`;

export const CustomMenu = styled(Menu)`
  & .MuiPaper-root {
    elevation: 0;
    overflow: visible;
    filter: drop-shadow(0rem 5px 10px rgba(0, 0, 0, 0.32));
    margin-top: -5px;

    & .MuiAvatar-root {
      width: 40px;
      height: 40px;
      margin-left: -10px;
      margin-right: 20px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50px; // 여기 값을 더 크게 설정
      left: 25px;
      width: 5px;
      height: 50px;
      background-color: white;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }
  }
`;

export const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "end", // 기본으로 center 적용
  backgroundColor: "#e5e8eb",
  borderRadius: "26px",
  padding: "6px",
  width: "100%",
}));

export const CustomInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  marginLeft: "15px",
  marginRight: "15px",
  minHeight: "40px",
}));

export const CustomIconButton = styled(IconButton)`
  align-self: end;
`;

export const ChattingTypeButton = styled(Box)({
  background: process.env.REACT_APP_MAIN_COLOR,
  width: "auto",
  height: "40px",
  borderRadius: "20px",
  boxSizing: "border-box",
  padding: "8px",
  display: "flex",
  flexDirection: "row",
  gap: "5px",
  cursor: "pointer",
});
