import React, { useState } from "react";
import {
  ArrowForward,
  ChatBubbleOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Pending,
  Search,
} from "@mui/icons-material";
import * as S from "./styled";
import { useAtom } from "jotai";
import {
  chattingTypeAtom,
  chattingValueAtom,
  ChatType,
  isChattingAskAtom,
  searchValueAtom,
  typeAvailableStateAtom,
} from "../../../../Atoms/ChatTestAtom";
import {
  handleClickAtom,
  handleCloseAndChangeTypeAtom,
  handleCloseAtom,
  handleInputValueAtom,
  handleKeyDown,
  handleSendMessage,
  whenDownButtonClickAtom,
  whenUpButtonClickAtom,
} from "../../../../ViewModels/ChatTestViewModel";
import {
  Box,
  IconButton,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export function RecommendChatInput() {
  const [isChattingAsk] = useAtom(isChattingAskAtom);
  const [, handleClick] = useAtom(handleClickAtom);
  const [, handleClose] = useAtom(handleCloseAtom);
  const [, handleCloseAndChange] = useAtom(handleCloseAndChangeTypeAtom);

  const [chattingType] = useAtom(chattingTypeAtom);
  const [chatInputValue] = useAtom(chattingValueAtom);
  const [searchInputValue] = useAtom(searchValueAtom);
  const [, onInputChange] = useAtom(handleInputValueAtom);
  const [, onSendMessage] = useAtom(handleSendMessage);
  const [, onKeyDown] = useAtom(handleKeyDown);
  const [typeAvailableState] = useAtom(typeAvailableStateAtom);

  const [, whenDownButtonClick] = useAtom(whenDownButtonClickAtom);
  const [, whenUpButtonClick] = useAtom(whenUpButtonClickAtom);

  const { t } = useTranslation();

  return (
    <>
      <S.CustomMenu
        anchorEl={isChattingAsk}
        id="account-menu"
        open={Boolean(isChattingAsk)}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => handleCloseAndChange(ChatType.ASK)}>
          <ListItemIcon>
            <ChatBubbleOutline />
          </ListItemIcon>
          <Typography>
            {t("Components.ChatTest.RecommendCenter.ChatInput.ask")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleCloseAndChange(ChatType.SEARCH)}>
          <ListItemIcon>
            <Search />
          </ListItemIcon>
          <Typography>
            {t("Components.ChatTest.RecommendCenter.ChatInput.search")}
          </Typography>
        </MenuItem>
      </S.CustomMenu>

      <S.InputContainer>
        <S.ChattingTypeButton onClick={handleClick}>
          {chattingType === ChatType.ASK ? (
            <ChatBubbleOutline sx={{ color: "white" }} />
          ) : (
            <Search sx={{ color: "white" }} />
          )}
          <KeyboardArrowDown sx={{ color: "white" }} />
        </S.ChattingTypeButton>

        <S.CustomInputBase
          className="chatbot-inputbse-custom"
          placeholder={
            chattingType === ChatType.ASK
              ? !typeAvailableState
                ? t(
                    "Components.ChatTest.RecommendCenter.ChatInput.makingAnswer"
                  )
                : t(
                    "Components.ChatTest.RecommendCenter.ChatInput.questionInput"
                  )
              : t("Components.ChatTest.RecommendCenter.ChatInput.searchInput")
          }
          multiline
          maxRows={9}
          value={
            chattingType === ChatType.ASK ? chatInputValue : searchInputValue
          }
          onChange={
            !typeAvailableState
              ? () => null
              : (e) => onInputChange(e.target.value)
          }
          onKeyDown={
            chattingType === ChatType.ASK && typeAvailableState
              ? (e) => onKeyDown(e, t)
              : () => null
          }
        />

        <Box
          sx={{
            background: !typeAvailableState
              ? process.env.REACT_APP_MAIN_COLOR
              : chattingType === ChatType.ASK
              ? chatInputValue === ""
                ? "#8f8f8f"
                : process.env.REACT_APP_MAIN_COLOR
              : searchInputValue === ""
              ? "#8f8f8f"
              : process.env.REACT_APP_MAIN_COLOR,
            width: "auto",
            height: "40px",
            borderRadius: "20px",
            boxSizing: "border-box",
            padding: "8px",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          {chattingType === ChatType.ASK ? (
            typeAvailableState ? (
              <IconButton onClick={() => onSendMessage(t)} sx={{ padding: 0 }}>
                <ArrowForward sx={{ color: "white" }} />
              </IconButton>
            ) : (
              <IconButton sx={{ padding: 0 }}>
                <Pending sx={{ color: "white" }} />
              </IconButton>
            )
          ) : (
            <>
              <IconButton
                onClick={() => whenDownButtonClick()}
                sx={{ padding: 0 }}
              >
                <KeyboardArrowDown sx={{ color: "white" }} />
              </IconButton>
              <IconButton
                onClick={() => whenUpButtonClick()}
                sx={{ padding: 0 }}
              >
                <KeyboardArrowUp sx={{ color: "white" }} />
              </IconButton>
            </>
          )}
        </Box>
      </S.InputContainer>
    </>
  );
}
