import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { errorAtom, userLanguageAtom } from "./Atoms/ChatTestAtom";
import { BasicCenter } from "./Components/ChatTest/BasicCenter";
import { RecommendCenter } from "./Components/ChatTest/RecommendCenter";
import { handleInitializeAtom } from "./ViewModels/ChatTestViewModel";

import { InteractionCenter } from "./Components/ChatTest/InteractionCenter";
import { useParams } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "./Locales/i18n";

export function ChatTest() {
  const [, handleInitialize] = useAtom(handleInitializeAtom);
  const [currentSideBar, setCurrentSideBar] = useState<string | null>(null);
  const [user, setUser] = useState<string | null>(null);
  const [error, setError] = useAtom(errorAtom);
  const [language, setUserLangauge] = useAtom(userLanguageAtom);

  const { slug } = useParams<{ slug: string }>();

  const { t } = useTranslation();

  // 허용된 origins
  const allowedOrigins = [
    "http://localhost:3000",
    "http://10.10.112.212:3000",
    "http://10.10.112.212:3004",
    "https://chat.dokgabi.ai",
    "https://dashboard.dokgabi.ai",
  ];

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { origin, data } = event;

      // origin 검증
      if (
        !allowedOrigins.some((allowedOrigin) =>
          allowedOrigin.includes("*")
            ? origin.endsWith(allowedOrigin.replace("*", ""))
            : allowedOrigin === origin
        )
      ) {
        setError(t("Pages.ChatTest.wrongDirection"));
        return;
      }

      // 메시지 데이터 검증 및 상태 업데이트
      const { userId, currentSidebar, language } = data || {};

      if (userId) {
        setUser(userId); // userId 상태 업데이트
      }

      if (currentSidebar) {
        setCurrentSideBar(currentSidebar); // currentSidebar 상태 업데이트
      }

      if (language) {
        console.log(language);
        i18n.changeLanguage(language);
        setUserLangauge(language);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (!slug) {
      setError(t("Pages.ChatTest.wrongDirection"));
      return;
    }

    if (!user) {
      setError(t("Pages.ChatTest.wait"));
      return;
    }

    handleInitialize(
      slug ?? "",
      user,
      language
        ? language
        : navigator.language.split("-")[0] === "ko"
        ? "ko"
        : "en",
      t
    );
  }, [slug, user, language]);

  //

  if (error) {
    return (
      <Paper
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f5f5f5", // Paper 색상
        }}
        elevation={3}
      >
        {/* 로고 영역 */}
        <Box
          component="img"
          src={`https://dashboard.dokgabi.ai/ieum_logo.png`} // 로고 이미지 경로
          alt="Service Logo"
          sx={{
            width: "150px",
            marginBottom: "20px",
          }}
        />

        {/* 에러 메시지 */}
        <Typography
          variant="h6"
          color={process.env.REACT_APP_MAIN_COLOR}
          sx={{
            fontWeight: 600,
            fontSize: "1rem", // 작은 폰트 크기
            textAlign: "center",
            maxWidth: "80%", // 긴 에러 메시지 처리
            wordBreak: "break-word",
          }}
        >
          {error}
        </Typography>
      </Paper>
    );
  }

  return (
    <Box sx={{ width: "100vw", height: "100vh" }}>
      {currentSideBar === "basic" ? (
        <BasicCenter />
      ) : currentSideBar === "recommend" ? (
        <RecommendCenter />
      ) : (
        <InteractionCenter />
      )}
    </Box>
  );
}
