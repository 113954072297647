import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";

interface MapProps {
  data?: {
    lat: number;
    lng: number;
    name: string;
    addr: string;
    desc: string;
  }[];
}

export function Map({ data }: MapProps) {
  const [selectedMarker, setSelectedMarker] = useState<number | null>(null); // 선택된 마커의 인덱스 저장
  const [googleLoaded, setGoogleLoaded] = useState(false); // Google Maps API 로드 상태

  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const defaultCenter = { lat: 37.5665, lng: 126.978 }; // 기본 지도 중심 (서울)

  const positions =
    Array.isArray(data) && data.length > 0
      ? data.map(({ lat, lng }) => ({ lat, lng }))
      : [];

  const customMarkerIcon = googleLoaded
    ? {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: "#4E229F",
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 10,
      }
    : undefined; // 로드되기 전에는 아이콘 설정 안 함

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGE_MAP_API_KEY}
      onLoad={() => setGoogleLoaded(true)} // Google Maps API 로드 완료 시 상태 업데이트
    >
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={positions.length > 0 ? positions[0] : defaultCenter}
        zoom={positions.length > 0 ? 13 : 10} // 데이터가 없을 경우 기본 줌 설정
      >
        {/* 마커 표시 */}
        {Array.isArray(data) &&
          data.map(({ lat, lng, name, addr, desc }, index) => (
            <Marker
              key={index}
              position={{ lat, lng }}
              title={name}
              onClick={() => setSelectedMarker(index)} // 마커 클릭 시 선택된 인덱스 저장
              label={{
                text: (index + 1).toString(),
                color: "#FFFFFF", // 레이블 텍스트 색상
                fontSize: "12px",
                fontWeight: "bold",
              }}
              icon={customMarkerIcon} // 커스텀 아이콘 적용
            />
          ))}

        {/* 선택된 마커에 대한 InfoWindow 표시 */}
        {selectedMarker !== null &&
          Array.isArray(data) &&
          data[selectedMarker] && (
            <InfoWindow
              position={{
                lat: data[selectedMarker].lat,
                lng: data[selectedMarker].lng,
              }}
              onCloseClick={() => setSelectedMarker(null)} // 닫기 버튼 클릭 시 InfoWindow 닫기
            >
              <div>
                <h4>{data[selectedMarker].name}</h4>
                <p>
                  <strong>addr:</strong> {data[selectedMarker].addr}
                </p>
                <p>
                  <strong>desc:</strong> {data[selectedMarker].desc}
                </p>
              </div>
            </InfoWindow>
          )}

        {/* 경로 그리기 */}
        {positions.length > 1 && (
          <Polyline
            path={positions}
            options={{
              strokeColor: "#4E229F", // 폴리라인 색상
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
}
