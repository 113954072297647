import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./En/translation.json";
import translationKO from "./Ko/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ko: {
    translation: translationKO,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  // lng: navigator.language.split("-")[0] === "ko" ? "ko" : "en",
  fallbackLng: "en",
  interpolation: {
    format: function (
      value: string | number | null | undefined,
      format: string | undefined
    ): string {
      if (format === "ordinal" && typeof value === "number") {
        const suffix =
          value === 1 ? "st" : value === 2 ? "nd" : value === 3 ? "rd" : "th";
        return `${value}${suffix}`;
      }
      return String(value ?? ""); // Ensuring a string return value
    },
    escapeValue: false,
  },
});

export default i18n;
